import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import Swal from 'sweetalert2';
import { User } from '../shared/models/user';
import { AuthApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  returnUrl: any;
  constructor(
    private api: AuthApiService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    let user = null
    if (localStorage.getItem("currentClient")) {
      user = JSON.parse(localStorage.getItem("currentClient")!)
    }
    this.currentUserSubject = new BehaviorSubject<User>(
      user!
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  influencer_login(data) {
    let form_data = new FormData();
    form_data.append('email', data.email)
    form_data.append('password', data.password)
    this.api.influencer_login(form_data).subscribe((res: any) => {
      if (res.status === 'success') {
        let param = btoa(encodeURIComponent(JSON.stringify({ 'custom_id': res.data.influencer_custom_id })))
        if (res.data.step_2 != true) {
          this.router.navigate(['/register-step2'], { queryParams: { data: param } })
        } else if (res.data.step_3 != true) {
          this.router.navigate(['/register-step3'], { queryParams: { data: param } })
        } else {
          let res_data = { ...res['data'], ...{ token: res['token'], role: 'influencer', company_custom_id: '123' } }
          localStorage.setItem('currentClient', JSON.stringify(res_data));
          if (localStorage.getItem('currentClient')) {
            this.currentUserSubject.next(res_data)
            this.router.navigate([this.returnUrl]);
            // this.router.navigate(['/influencer/campaigns/campaign-list']);
          }
        }
      }
      // else {
      //   Swal.fire({
      //     icon: 'info',
      //     title: this.translate.instant('Error'),
      //     text: res.message
      //   })
      // }
    })
  }

  company_Login(data) {
    const form_data = new FormData();
    form_data.append('email', data.email);
    form_data.append('password', data.password);
    this.api.companyLogin(form_data).subscribe((res: any) => {
      if (res.status == 'success') {
        let res_data = { ...res['data'], ...{ token: res['token'], role: 'company', influencer_custom_id: '123' } }
        localStorage.setItem('currentClient', JSON.stringify(res_data));
        if (localStorage.getItem('currentClient')) {
          this.currentUserSubject.next(res_data)
          this.router.navigate([this.returnUrl]);
          // this.router.navigate(['/company/profile']);
        }
      }
    })
  }

  all_logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentClient');
    if (!localStorage.getItem('currentClient')) {
      // this.router.navigate(['/login']).then(()=>{
      this.currentUserSubject.next(null)
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      })
      // });
    }
    return of({ success: false });
  }
}
