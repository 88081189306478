import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formOpen : boolean = false;
  is_company: boolean = false;
  is_influencer: boolean = false;
  login_form: FormGroup
  isNewPasswordText = false

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    if (this.formOpen) {
      // Check which context you are in (influencer or company) and call the appropriate login method
      if (this.is_influencer) {
        this.onInfluencerLogin();
      } else if (this.is_company) {
        this.onCompanyLogin();
      }
    }
  }

  constructor(
    private fb: FormBuilder,
  
    private auth: AuthService,
    private router: Router
  ) {
    // redirect to home if already logged in
    if (this.auth.currentUserValue) {
      this.router.navigate(['/'])
    }
  }

  ngOnInit(): void {
    this.login_form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    })
  }

  toggleNewPassword(){
    this.isNewPasswordText = ! this.isNewPasswordText
  }

  influencer_click() {
    this.formOpen = true
    this.is_influencer = true
    this.is_company = false
    // this.change_to_company()
    this.login_form.reset();
  }

  company_click() {
    this.formOpen = true
    this.is_company = true
    this.is_influencer = false
    // this.change_to_company();
    this.login_form.reset();
  }

  onInfluencerLogin() {
    this.markFormGroupTouched(this.login_form);
    if (this.login_form.invalid) {
      return;
    }
    let data = {
      email: this.login_form.get('email').value,
      password: this.login_form.get('password').value
    }

    this.auth.influencer_login(data);
  }

  onCompanyLogin() {
    this.markFormGroupTouched(this.login_form);
    if (this.login_form.invalid) {
      return;
    }
    let data ={
      email : this.login_form.get('email').value,
      password : this.login_form.get('password').value
    }
    this.auth.company_Login(data);
    // console.log("Company", this.login_form.value)
  }


  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
