
<!-- Register ste 2 -->
<div class="user-form-area form-are2" style="background-image: url('assets/img/login_bg.png'); background-size: cover;">
    <!-- <div class="user-form-area"> -->
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img2">
                    <img src="assets/img/registration.png" alt="User">
                </div>
            </div>

        <div class="col-lg-6 p-0 col-md-12">
            <div class="user-content">
                <div class="top">
                    <h2>{{"Registration" | translate}} - {{"Step" | translate}} 2</h2>
                    <!-- <p class="mb-4 text-muted tx-13 ms-0" translate>Get the verification code
                                    from your email</p> -->
                    <form [formGroup]="register_form2" class="mt-3">
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4"> {{computer_1}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio1" name="computer"
                                            value="Yes, Fixed" formControlName="computer" checked>
                                        <label class="form-check-label" for="radio1" translate>Yes, Fixed</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio2" name="computer"
                                            value="Yes, Landline and Portable" formControlName="computer">
                                        <label class="form-check-label" for="radio2" translate>Yes, Landline and
                                            Portable</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio3" name="computer"
                                            value="Yes, Laptop" formControlName="computer">
                                        <label class="form-check-label" for="radio3" translate>Yes, Laptop</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio4" name="computer"
                                            value="No, I don't use a computer" formControlName="computer">
                                        <label class="form-check-label" for="radio4" translate>No, I don't use a
                                            computer</label>
                                    </div>
                                    <ng-container class="invalid"
                                        *ngIf="register_form2.get('computer')?.hasError('required') && (register_form2.get('computer')?.touched || register_form2.get('computer')?.dirty)">
                                        <small class="text-danger" translate>Please select option</small>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4"> {{email_2}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio5" name="check_email"
                                            value="Daily" formControlName="check_email" checked>
                                        <label class="form-check-label" for="radio5" translate>Daily</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio6" name="check_email"
                                            value="Twice per week" formControlName="check_email">
                                        <label class="form-check-label" for="radio6" translate>Twice per week</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio7" name="check_email"
                                            value="Occasionally" formControlName="check_email">
                                        <label class="form-check-label" for="radio7" translate>Occasionally</label>
                                    </div>
                                    <ng-container class="invalid"
                                        *ngIf="register_form2.get('check_email')?.hasError('required') && (register_form2.get('check_email')?.touched || register_form2.get('check_email')?.dirty)">
                                        <small class="text-danger" translate>Please select option</small>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4"> {{internet_habit_3}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <ng-container *ngFor="let data of internet_habits; let i = index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" (click)="habit_click(i)"
                                                [(ngModel)]="data.checked" [ngModelOptions]="{standalone: true}">
                                            <label class="form-check-label">{{data.option}}</label>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="show_habit == true">
                                        <input type="text" class="form-control" placeholder="{{'Other' | translate}}"
                                            style="background-color: #fff;" formControlName="internet_habit">
                                        <ng-container class="invalid"
                                            *ngIf="register_form2.get('internet_habit')?.hasError('required') && (register_form2.get('internet_habit')?.touched || register_form2.get('internet_habit')?.dirty)">
                                            <small class="text-danger" translate>This filed is required</small>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4"> {{social_network_4}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <ng-container *ngFor="let data of social_networks; let i = index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" (click)="social_network_click(i)"
                                                [(ngModel)]="data.checked" [ngModelOptions]="{standalone: true}">
                                            <label class="form-check-label">{{data.option}}</label>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="show_social_network == true">
                                        <input type="text" class="form-control" placeholder="{{'Other' | translate}}"
                                            style="background-color: #fff;" formControlName="social_network">
                                        <ng-container class="invalid"
                                            *ngIf="register_form2.get('social_network')?.hasError('required') && (register_form2.get('social_network')?.touched || register_form2.get('social_network')?.dirty)">
                                            <small class="text-danger" translate>This filed is required</small>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4"> {{passion_5}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <ng-container *ngFor="let data of passion; let i = index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" (click)="passion_click(i)"
                                                [(ngModel)]="data.checked" [ngModelOptions]="{standalone: true}">
                                            <label class="form-check-label">{{data.option}}</label>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="show_passion == true">
                                        <input type="text" class="form-control" placeholder="{{'Other' | translate}}"
                                            style="background-color: #fff;" formControlName="passion">
                                        <ng-container class="invalid"
                                            *ngIf="register_form2.get('passion')?.hasError('required') && (register_form2.get('passion')?.touched || register_form2.get('passion')?.dirty)">
                                            <small class="text-danger" translate>This filed is required</small>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4"> {{followers_5}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio8" name="followers"
                                            value="1K - 5K" formControlName="followers" checked>
                                        <label class="form-check-label" for="radio8">1K - 5K</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio9" name="followers"
                                            value="5K - 10K" formControlName="followers">
                                        <label class="form-check-label" for="radio9">5K - 10K</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio10" name="followers"
                                            value="10K - 50K" formControlName="followers">
                                        <label class="form-check-label" for="radio10">10K - 50K</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio11" name="followers"
                                            value="50K - 100K" formControlName="followers">
                                        <label class="form-check-label" for="radio11">50K - 100K</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio12" name="followers"
                                            value="100K - 500K" formControlName="followers">
                                        <label class="form-check-label" for="radio12">100K - 500K</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio13" name="followers"
                                            value="500K - 1Million" formControlName="followers">
                                        <label class="form-check-label" for="radio13">500K - 1Million</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio14" name="followers"
                                            value="+ 1 Million" formControlName="followers">
                                        <label class="form-check-label" for="radio14">+ 1 Million</label>
                                    </div>
                                    <ng-container class="invalid"
                                        *ngIf="register_form2.get('followers')?.hasError('required') && (register_form2.get('followers')?.touched || register_form2.get('followers')?.dirty)">
                                        <small class="text-danger" translate>Please select option</small>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4">{{post_6}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio15" name="post" value="Every day"
                                            formControlName="post" checked>
                                        <label class="form-check-label" for="radio15" translate>Every day</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio16" name="post"
                                            value="Twice a week" formControlName="post">
                                        <label class="form-check-label" for="radio16" translate>Twice a week</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio17" name="post"
                                            value="Once a week" formControlName="post">
                                        <label class="form-check-label" for="radio17" translate>Once a week</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio18" name="post"
                                            value="Twice a month" formControlName="post">
                                        <label class="form-check-label" for="radio18" translate>Twice a month</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio19" name="post"
                                            value="Once a month" formControlName="post">
                                        <label class="form-check-label" for="radio19" translate>Once a month</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio20" name="post"
                                            value="Occasionally" formControlName="post">
                                        <label class="form-check-label" for="radio20" translate>Occasionally</label>
                                    </div>
                                    <ng-container class="invalid"
                                        *ngIf="register_form2.get('post')?.hasError('required') && (register_form2.get('post')?.touched || register_form2.get('post')?.dirty)">
                                        <small class="text-danger" translate>Please select option</small>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4"> {{goal_7}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <ng-container *ngFor="let data of goal; let i = index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" (click)="goal_click(i)"
                                                [(ngModel)]="data.checked" [ngModelOptions]="{standalone: true}">
                                            <label class="form-check-label">{{data.option}}</label>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="show_goal == true">
                                        <input type="text" class="form-control" placeholder="{{'Other' | translate}}"
                                            style="background-color: #fff;" formControlName="goal">
                                        <ng-container class="invalid"
                                            *ngIf="register_form2.get('goal')?.hasError('required') && (register_form2.get('goal')?.touched || register_form2.get('goal')?.dirty)">
                                            <small class="text-danger" translate>This filed is required</small>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4">{{behave_8}}
                                        <span class="text-danger">* </span>
                                    </label>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio21" name="behaviour"
                                            value="Not important" formControlName="behaviour" checked>
                                        <label class="form-check-label" for="radio21" translate>Not important</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio22" name="behaviour"
                                            value="Unimportant" formControlName="behaviour">
                                        <label class="form-check-label" for="radio22" translate>Unimportant</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio23" name="behaviour"
                                            value="Very important" formControlName="behaviour">
                                        <label class="form-check-label" for="radio23" translate>Very important</label>
                                    </div>
                                    <ng-container class="invalid"
                                        *ngIf="register_form2.get('behaviour')?.hasError('required') && (register_form2.get('behaviour')?.touched || register_form2.get('behaviour')?.dirty)">
                                        <small class="text-danger" translate>Please select option</small>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4"> {{ethical_9}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <ng-container *ngFor="let data of ethical_opinion; let i = index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" [(ngModel)]="data.checked"
                                                [ngModelOptions]="{standalone: true}">
                                            <label class="form-check-label">{{data.option}}</label>
                                        </div>
                                    </ng-container>
        
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4"> {{trust_10}}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio24" name="trust"
                                            value="Not trusting at all" formControlName="trust" checked>
                                        <label class="form-check-label" for="radio24" translate>Not trusting at all</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio25" name="trust"
                                            value="Rather not trust" formControlName="trust">
                                        <label class="form-check-label" for="radio25" translate>Rather not trust</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio26" name="trust"
                                            value="Somewhat Confident" formControlName="trust">
                                        <label class="form-check-label" for="radio26" translate>Somewhat Confident</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio27" name="trust"
                                            value="Totally confident" formControlName="trust">
                                        <label class="form-check-label" for="radio27" translate>Totally confident</label>
                                    </div>
                                    <ng-container class="invalid"
                                        *ngIf="register_form2.get('trust')?.hasError('required') && (register_form2.get('trust')?.touched || register_form2.get('trust')?.dirty)">
                                        <small class="text-danger" translate>Please select option</small>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4">{{opinions_11}}
                                        <span class="text-danger">* </span>
                                    </label>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio28" name="opinion" value="No"
                                            formControlName="opinion" checked>
                                        <label class="form-check-label" for="radio28" translate>No</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio29" name="opinion" value="Yes"
                                            formControlName="opinion">
                                        <label class="form-check-label" for="radio29" translate>Yes</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio30" name="opinion"
                                            value="I don't know" formControlName="opinion">
                                        <label class="form-check-label" for="radio30" translate>I don't know</label>
                                    </div>
                                    <ng-container class="invalid"
                                        *ngIf="register_form2.get('opinion')?.hasError('required') && (register_form2.get('opinion')?.touched || register_form2.get('opinion')?.dirty)">
                                        <small class="text-danger" translate>Please select option</small>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
        
                        <div class="card mt-3">
                            <div class="card-body">
                                <div class="form-group">
                                    <label class="mb-4">{{brand_commitment_12}}
                                        <span class="text-danger">* </span>
                                    </label>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio31" name="brand_commitment"
                                            value="Yes" formControlName="brand_commitment" checked>
                                        <label class="form-check-label" for="radio31" translate>Yes</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio32" name="brand_commitment"
                                            value="No" formControlName="brand_commitment">
                                        <label class="form-check-label" for="radio32" translate>No</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="radio" class="form-check-input" id="radio33" name="brand_commitment"
                                            value="I don't know" formControlName="brand_commitment">
                                        <label class="form-check-label" for="radio33" translate>I don't know</label>
                                    </div>
                                    <ng-container class="invalid"
                                        *ngIf="register_form2.get('brand_commitment')?.hasError('required') && (register_form2.get('brand_commitment')?.touched || register_form2.get('brand_commitment')?.dirty)">
                                        <small class="text-danger" translate>Please select option</small>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- <svg width="200" height="55" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;"
                            (click)="submit()">
                            <rect x="0" y="0" width="200" height="55" rx="25" ry="25" fill="#db5e70" />
                            <text x="50%" y="50%" text-anchor="middle" dy=".3em" fill="#fff" font-size="16"
                                font-family="Arial, sans-serif">
                                <a class="fw-bold fs-4">{{'Submit' |translate}}</a>
                            </text>
                        </svg> -->
                        <button type="button" (click)="submit()" class="btn mt-3" translate>Submit</button>
                    </form>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
<!-- End Register -->