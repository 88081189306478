<!-- Footer -->
<footer class="pt-70 pb-70">
    <div class="container">
        <div class="row text-center">
            <div class="col-sm-6 col-lg-4 custom-line">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a class="logo" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                        <ul>
                            <li>
                                <h3>{{"The future already started" | uppercase}}</h3>
                            </li>
                            <li><span>&copy;&nbsp;2023. {{"Spotlight" | uppercase}} <br>{{"all right reserved" |
                                    titlecase}}.</span></li>
                            <!-- <li><span>Message: </span><a href="mailto:hello@alzo.com">hello@alzo.com</a></li>
                            <li><span>Phone: </span><a href="tel:2151234567">215 - 123 - 4567</a></li>
                            <li><span>Open: </span>Mon - Fri / 9:00 AM - 6:00 PM</li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 custom-line">
                <div class="footer-item" style="text-align: left; margin-left: 20px;">
                    <div class="footer-service">
                        <h1 class="heading-text">{{"Contact" | uppercase}}</h1>

                        <ul>
                            <li>
                                <i class="bx bx-location-plus" style="font-size: 30px;"></i>
                                <div class="fleft">
                                    <a class="" href="">lorem ipsum est un, Hydra;
                                        Alger</a>
                                </div>
                            </li>
                            <li>
                                <i class="bx bx-envelope" style="font-size: 30px;"></i>
                                <div class="fleft">
                                    <a class="" href="">hello@spotlight-dz.com</a>
                                </div>
                            </li>
                            <li>
                                <i class="bx bx-phone" style="font-size: 30px;"></i>
                                <div class="fleft">
                                    <a href="">(+213) 000 000 000</a>
                                </div>
                            </li>
                            <!-- <li><a routerLink="/jobs">Customer Service</a></li>
                            <li><a routerLink="/jobs">Digital Marketing</a></li>
                            <li><a routerLink="/jobs">Web & Software Dev</a></li>
                            <li><a routerLink="/jobs">Science & Analytics</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="footer-item" style="text-align: left; margin-left: 20px;">
                    <div class="footer-service">
                        <h1 class="heading-text">Social Media</h1>

                        <ul class="cust-footer-social-icon" style="display: flex !important;">
                            <li style="margin-right: 10px;">
                                <a target="_blank" title="Connect on Instagram" href=""> 
                                  <img src="assets/img/insta_icon.svg" />
                                </a>
                            </li>
                            <li style="margin-right: 10px;">
                                <a target="_blank" title="Connect on Twitter" href=""> 
                                  <img src="assets/img/twiter_icon.svg" />
                                </a>
                            </li>
                            <li style="margin-right: 10px;">
                                <a target="_blank" title="Connect on Tiktok" href=""> 
                                  <img src="assets/img/tiktok_icon.svg" />
                                </a>
                            </li>
                            <li style="margin-right: 10px;">
                                <a target="_blank" title="Connect on LinkedIn" href=""> 
                                  <img src="assets/img/linkedin_icon.svg" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-newsletter">
                        <h3>Newsletter</h3>
                        <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed</p>
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Your email*" name="EMAIL" required autocomplete="off">
                            <button class="btn" type="submit"><i class="flaticon-send"></i></button>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</footer>
<!-- End Footer -->

<!-- Copyright -->
<!-- <div class="copyright-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin-square'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <p>© Alzo is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Copyright -->