import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthApiService } from '../../services/auth-api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contact_form !: FormGroup
  constructor(
    private fb : FormBuilder,
    private api : AuthApiService,
    private toast : ToastrService,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.contact_form = this.fb.group({
      fullName : ['', [Validators.required]],
      email : ['', [Validators.required, Validators.email]],
      phone_no : ['', ''],
      message : ['', [Validators.required]],
    })
  }

  sendMessage(){
    this.markFormGroupTouched(this.contact_form);
    if( this.contact_form.invalid){
    
      return;
    }
    let form_data = new FormData();
    form_data.append('name', this.contact_form.get('fullName')?.value)
    form_data.append('email', this.contact_form.get('email')?.value)
    form_data.append('mobile', this.contact_form.get('phone_no')?.value)
    form_data.append('message', this.contact_form.get('message')?.value)
    this.api.contact_us(form_data).subscribe((res : any)=>{
      if(res.status == 'success'){
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        })
      } 
      this.router.navigate(['/']);
      this.contact_form.reset();
    })
    
  }
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar) || event.code === 'Space') {
      event.preventDefault();
    }
  }
}
