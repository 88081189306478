import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthApiService } from '../../services/auth-api.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forget-password-company',
  templateUrl: './forget-password-company.component.html',
  styleUrls: ['./forget-password-company.component.scss']
})
export class ForgetPasswordCompanyComponent implements OnInit {

  forgot_pass_form: FormGroup;
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.submit();
  }
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authApi : AuthApiService,
    private translate : TranslateService
  ) {}

  ngOnInit(): void {
    this.forgot_pass_form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  submit() {
    this.markFormGroupTouched(this.forgot_pass_form)
    if(this.forgot_pass_form.invalid) {
      return;
    }
    let form_data = new FormData();
    form_data.append('email', this.forgot_pass_form.get('email')?.value);
    form_data.append('forgot_password_link', `${environment.baseUrl}reset-company-pass/`)
    this.authApi.forgotCompanyPass(form_data).subscribe((res: any)=>{
      if (res.status=='success'){
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        })
      } else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res.message
        })
      }
    })


  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
