import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
// export const AuthGuard = () => {

// }
export class AuthGuard {
  constructor(
    private auth: AuthService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.auth.currentUserValue) {
        const user_type = this.auth.currentUserValue.role
        if(route.data['role'] && route.data['role'].indexOf(user_type) === -1) {
          this.router.navigate(['/login'])
          return false;
        }
        return true;
      }
      this.router.navigate(['/login'])
    return false;
  }
  
}
