import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '../../services/auth-api.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  showShortDesciption = true
  showShortDesciption2 = true
  showNavigationIndicators = false;
  show_talent_detail = false;
  showAllData = false;
  image_array =[];
  profile_one;
  profile_two;
  profile_three;
  profile_four;
  talent_details;
  // image_array = [
  //   {
  //     id: 1,
  //     image: "./assets/img/candidate1.jpg",
  //     name: "User 1"
  //   },
  //   {
  //     id: 2,
  //     image: "./assets/img/candidate2.jpg",
  //     name: "User 2"
  //   },
  //   {
  //     id: 3,
  //     image: "./assets/img/candidate3.jpg",
  //     name: "User 3"
  //   },
  //   {
  //     id: 4,
  //     image: "./assets/img/candidate4.jpg",
  //     name: "User 4"
  //   },
  //   {
  //     id: 5,
  //     image: "./assets/img/candidate5.jpg",
  //     name: "User 5"
  //   },
  //   {
  //     id: 6,
  //     image: "./assets/img/candidate6.jpg",
  //     name: "User 6"
  //   },
  // ]
  constructor(
    private router: Router,
    private api: AuthApiService,
    public auth : AuthService
  ) { }

  ngOnInit(): void {
    this.talent_list()
  }

  talent_list() {
    let fd = new FormData()
    this.api.get_talent_list(fd).subscribe((res: any) => {
      this.image_array = res.recently_5_users;
      this.profile_one = this.image_array[0]
      this.profile_two = this.image_array[1]
      this.profile_three = this.image_array[2]
      this.profile_four = this.image_array[3]
    })
  }
  
  toggleMute() {
    var video = <HTMLVideoElement>document.getElementById('video');
    video.muted = !video.muted;
    console.log('toggled')
  }

  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption
 }
  alterDescriptionText2() {
    this.showShortDesciption2 = !this.showShortDesciption2
 }

 view_details(item:any) {
   this.router.navigate(['/user/details'], { queryParams: { id: item.custom_id}})
 
  // let fd = new FormData();
  // fd.append('influencer_custom_id', item.custom_id)
  // this.api.get_talent_byId(fd).subscribe((res: any) => {
  //   if(res.status == 'success') {
  //     this.talent_details = res.influencer_data;
  //     this.show_talent_detail = true;
  //   } else {
  //     this.show_talent_detail = false
  //   }
  // })
}

  read_more() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }

  registration() {
    this.router.navigate(['/register'])
  }

}
