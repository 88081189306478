import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthApiService } from '../../services/auth-api.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-otp-email-company',
  templateUrl: './otp-email-company.component.html',
  styleUrls: ['./otp-email-company.component.scss']
})
export class OtpEmailCompanyComponent implements OnInit {
company_id;
company_custom_id;
  otp_form: FormGroup;
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.verify_otp();
  }
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private api : AuthApiService,
    private translate : TranslateService,
    private route : ActivatedRoute
  ) {
    this.route.queryParams.subscribe((param)=>{
      if(param && param.data){
        let data= JSON.parse(decodeURIComponent(atob(param.data)))
        this.company_id = data.id;
        this.company_custom_id = data.custom_id
        // console.log(this.company_id);
        // console.log(this.company_custom_id);
        
      } else{
        this.router.navigate(['/register'])
      }
    })
  }

  ngOnInit(): void {
    this.otp_form = this.fb.group({
      otp: ['', [Validators.required, Validators.pattern("^[0-9]*$") ]]
    })
  }

  get f(): { [key: string]: AbstractControl } {
		return this.otp_form.controls;
	}

  verify_otp() {
    this.markFormGroupTouched(this.otp_form)
    if (this.otp_form.invalid) {
      return
    }
    let form_data = new FormData();
    // form_data.append('company_email_id', user_data.email);
    form_data.append('company_custom_id', this.company_custom_id);
    form_data.append('company_email_otp', this.otp_form.get('otp')?.value);

    form_data.forEach((value , key)=>{
      // console.log(key +" " + value);
    })
    this.api.verifyCompanyOtp(form_data).subscribe((res : any)=>{
      if(res.status=='success'){
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        }).then(() => {
          this.router.navigate(['/login'])
        })
        // localStorage.removeItem('company_data');
        // localStorage.clear();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res.message
        })
      }
    })
    // console.log('form submitted')
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
     * Marks all controls in a form group as touched
     * @param formGroup The form group to touch
     */
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
