import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LanguageService } from '../../services/language.service';
declare let $: any;

@Component({
  selector: 'app-navbar-style-three',
  templateUrl: './navbar-style-three.component.html',
  styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent implements OnInit {
  currentLanguage: string | undefined;
  flagvalue;
  countryName;
  langStoreValue;
  defaultFlag!: string;
  constructor(
    public auth: AuthService,
    public languageService: LanguageService
  ) { }
  listLang = [
    { text: "English", flag: "./assets/img/en.svg", lang: "en" },
    { text: "French", flag: "./assets/img/french_flag.jpg", lang: "fr" },
    // { text: "Germany", flag: "./assets/img/flags/germany_flag.jpg", lang: "de" },
    // { text: "Spain", flag: "./assets/img/flags/spain_flag.jpg", lang: "de" },
  ];
  ngOnInit(): void {
    this.langStoreValue = localStorage.getItem("lang");
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    if (localStorage.getItem('lang')) {
      this.countryName = val.map((element) => element.text);
    } else {
      this.countryName = 'English';
    }
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = "./assets/img/en.svg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    var self = this;
    $(document).on('click', '.lang-selector', function () {
      let text = $(this).text();
      let lang = $(this).data('lang');
      self.languageToggleMobile(text, lang);
      if (window.innerWidth <= 990) {
        // Refresh the page only on mobile screens
        location.reload();
      }
    });

    $(document).on('click', '.logout-selector', function () {
      self.auth.all_logout()
    })
  }

  languageToggleMobile(text: string, lang: string) {
    this.langStoreValue = lang;
    this.countryName = text;
    this.languageService.setLanguage(lang);
  }

  languageToggle(text: string, lang: string) {
    this.countryName = text;
    // this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }

  logout() {
    this.auth.all_logout()
  }

}
