<!-- Navbar -->
<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
    </div>
    
    <div class="main-nav menu-shrink">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Home <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo One</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo Two</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo Three</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{"Home" | translate}}</a></li>

                        <!-- <li class="nav-item"><a routerLink="/talent" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{"Talent" | translate}}</a></li> -->

                        <!-- <li class="nav-item">
                            <span class="tooltip-span">Hot</span>

                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">{{"Platform" | uppercase | translate}} <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{"For Talent" | uppercase}}</a></li>

                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{"For Business" | uppercase}}</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Users <i class='bx bx-chevron-down'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a></li>

                                        <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Employers <i class='bx bx-chevron-down'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/employers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Employers</a></li>

                                        <li class="nav-item"><a routerLink="/employer-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Employer Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dashboard</a></li>

                                <li class="nav-item"><a routerLink="/single-resume" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Single Resume</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & COnditions</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li> -->

                        <!-- <li class="nav-item">
                            <span class="tooltip-span">Hot</span>

                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Jobs <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/jobs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Jobs</a></li>

                                <li class="nav-item"><a routerLink="/favourite-jobs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Favourite Jobs</a></li>

                                <li class="nav-item"><a routerLink="/job-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Job Details</a></li>

                                <li class="nav-item"><a routerLink="/post-a-job" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Post A Job</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <span class="tooltip-span two">New</span>

                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Candidates <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/candidates" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Candidates</a></li>

                                <li class="nav-item"><a routerLink="/candidate-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Candidate Details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item"><a routerLink="/career" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{"Career" | translate}}</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{"Contact" | translate}}</a></li>

                        <ng-container *ngIf="auth.currentUserValue">
                            <ng-container *ngIf="auth.currentUserValue.role == 'influencer'">
                                <li class="nav-item"><a routerLink="/influencer/campaigns/campaign-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{"Campaigns" | translate}}</a></li>
                            </ng-container>
                        </ng-container>


                    <ng-container *ngIf="auth.currentUserValue">
                        <ng-container *ngIf="auth.currentUserValue.role == 'company'">
                            <li class="nav-item"><a routerLink="/company/influencers" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{"Influencers" | translate}}</a>
                            </li>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!auth.currentUserValue">
                        <li class="nav-item">
                            <a routerLink="/inflist" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                {{"Creators" | translate}}
                            </a>
                        </li>
                    </ng-container>
                    
                        <!-- language dropdown -->
                        <li class="nav-item">
                            <!-- <span class="tooltip-span">Hot</span> -->
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">{{countryName}} <i
                                    class='bx bx-chevron-down'></i></a>
                        
                            <ul class="dropdown-menu">
                                <li class="nav-item" *ngFor="let item of listLang"><a attr.data-lang="{{item.lang}}" class="nav-link lang-selector" 
                                        style="cursor: pointer;" (click)="languageToggle(item.text, item.lang)">{{item.text}}</a></li>
                        
                                <!-- <li class="nav-item"><a  class="nav-link"
                                        >{{"French" | uppercase}}</a></li> -->
                            </ul>
                        </li>
                    </ul>

                    <div class="side-nav auth-class">
                        <ng-container *ngIf="auth.currentUserValue">
                            <ng-container *ngIf="auth.currentUserValue.role == 'influencer'">
                                <a class="login-left" routerLink="/influencer/profile"><i class="bx bx-user"></i> {{"Profile" | translate}}</a>
                            </ng-container>
                            <ng-container *ngIf="auth.currentUserValue.role == 'company'">
                                <a class="login-left" routerLink="/company/profile"><i class="bx bx-user"></i> {{"Profile" | translate}}</a>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!auth.currentUserValue">
                            <a class="login-left" routerLink="/login"><i class="flaticon-enter"></i> {{"Login" | translate}}</a>        
                        </ng-container>
                        <ng-container *ngIf="auth.currentUserValue">
                            <a class="login-left logout-selector" style="cursor: pointer;"><i class="bx bx-log-out"></i> {{"Log Out" | translate}}</a>
                        </ng-container>
                        <!-- <a class="login-left" routerLink="/register"><i class="flaticon-enter"></i> Login/Register</a>

                        <a class="job-right" routerLink="/post-a-job">Post A Job <i class='bx bx-plus'></i></a> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar -->