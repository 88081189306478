<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->


<div class="new-area pt-100 pb-70"
    style="background-image: url(./assets/img/career/background.png); background-size: cover; height: 100vh;">
    <div class="container">
            
        <div class="row">
            <div class="col-lg-6 col-md-12 align-self-center">
               <div class="career-text">
                <div class="section-title">
                    <h2 translate>CAREER</h2>
                </div>
                    <span><i translate>
                            Join Spotlight and shape the future of Creators Marketing
                        </i>
                    </span>
                    <br>
                    <span>
                        <i translate>
                            Passionate about Creators marketing? Ready to make an impact? Join Spotlight and be part of our dynamic team
                            shaping the
                            future of the industry.
                        </i>
                    </span>
                    <br>
                    <span>
                        <i translate>
                            Experience innovation, collaborate with experts, and unlock endless learning opportunities. Make your mark
                            on successful
                            campaigns and drive business growth.
                        </i>
                    </span>
                    <!-- <div class="row mt-5">
                                    <div class="col-md-3 col-lg-3 col-sm-3 p-0">
                                        <img src="assets/svgs/Click.svg" alt="join_us">
                                    </div>
                                </div> -->
                    <p class="mt-3">
                        <svg width="150" height="50" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                            <rect x="0" y="0" width="150" height="50" rx="25" ry="25" fill="#db5e70" />
                            <text x="50%" y="50%" text-anchor="middle" dy=".3em" fill="#fff" font-size="16"
                                font-family="Arial, sans-serif">
                                <a href="mailto:contact@spotlightdz.dz" class="fw-bolder">{{'JOIN US' |translate}}</a>
                            </text>
                        </svg>
                
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 m-0 p-0">
                <div class="new-img">
                    <img src="./assets/img/career/career.png" alt="New">
                    <!-- <img src="assets/img/about-shape.png" alt="New"> -->
                </div>
            </div>
        </div>
    </div>
</div>
<section class="work-area">
    <div class="container-fluid">
        <div class="section-title">
            <h2 class="text-center" translate>LET'S WORK TOGETHER</h2>
        </div>
       <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="work-light">
                    <span class="work-heading-light" translate>Unlock Collaboration Opportunities with Local and International
                        Brands</span>
                    <p><i translate>Join our vibrant community of content creators dedicated to delivering exceptional
                            experiences to their communities. At
                            Spotlight, we take your creativity to new heights.</i></p>
        
                    <li><i translate>Experience professionalism in Transparent Collaborations.
                            We understand the importance of maintaining high standards of honesty in our brand partnerships. Let
                            us support you in
                            becoming the best content creator you can be. With us, you can focus on unleashing your creativity
                            while we handle the
                            rest.</i></li>
        
                    <li><i translate>A Unique Approach: Respecting Your Efforts, Communities, and Content.</i></li>
        
                    <li><i translate>Our team thinks differently. We believe in strategies that respect your hard work, your
                            communities, and the value of
                            your content. Join us at Spotlight and discover a refreshing approach to content creation marketing.
                        </i></li>
                </div>
                <!-- <div class="" style="margin-top: 35px;"> -->
                <!-- <div class="action-button text-center  align-self-bottom" style="margin-top: 35px;">
                            <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                                <a type="button" class="btn btn-lg btn-primary reg-log-button" routerLink="/register" translate>Registration</a>
                                <button type="button" class="btn or-button" style="cursor: text;" translate>OR</button>
                                <a type="button" class="btn btn-lg btn-danger reg-log-button" routerLink="/login" translate>Login</a>
                            </div>
                            <div class="ui-group-buttons">
                                <button type="button" class="btn btn-primary btn-lg">Registration</button>
                                <div class="or or-lg"></div>
                                <button type="button" class="btn btn-success btn-lg">Login</button>
                            </div>
                        </div> -->
            </div>
            <div class="col-md-6">
                <div class="work-light">
                    <span class="work-heading-light" translate>At Spotlight, we bring together the power of data and the
                        boundless creativity of our talented content creators to
                        deliver incredible campaigns for your brand.</span>
                    <p><i translate>Research: Creative. Talented. Passionate. Tap into our extensive network and discover the
                            perfect content creator for
                            your brand. We curate a diverse pool of exceptional individuals who align with your vision.</i></p>
        
                    <li><i translate>Activation: Harnessing the potential of artificial intelligence, our solution drives
                            optimal results for your campaigns.
                            We continuously improve and enhance your outcomes with cutting-edge technology.</i></li>
        
                    <li><i translate>Strategy and Content: Our dedicated teams are here to guide you in implementing your
                            strategy and crafting captivating
                            content. We collaborate closely with you to ensure your brand message resonates with your target
                            audience.</i></li>
        
                    <li><i translate>Reporting: Simplify and streamline your content creator marketing campaigns with our
                            comprehensive reporting solution.
                            Track and analyze results in one convenient place, empowering you to maximize your return on
                            investment. Gain valuable
                            insights and make informed decisions for future success.</i></li>
        
                    <li><i translate>Join Spotlight today and witness the power of collaboration between data-driven insights
                            and exceptional content
                            creators. Together, we'll create remarkable campaigns that captivate and inspire.
                        </i></li>
        
        
                    <!-- <div class="mt-3">
                                <a class="btn">E-mail us here</a>
                            </div> -->
        
                </div>
                <!-- <div class="action-button text-center align-self-bottom">
                            <div class="btn-group mb-2" role="group" aria-label="Basic mixed styles example">
                                <a type="button" class="btn btn-lg btn-primary reg-log-button" routerLink="/register" translate>Registration</a>
                                <button type="button" class="btn or-button" translate>OR</button>
                                <a type="button" class="btn btn-lg btn-danger reg-log-button" routerLink="/login" translate>Login</a>
                            </div>
                        </div> -->
            </div>
        </div>
       </div>
        <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div class="action-button text-center align-self-bottom">
                    <div class="btn-group mb-2" role="group" aria-label="Basic mixed styles example">
                        <a type="button" class="btn btn-lg btn-primary reg-log-button" routerLink="/register" translate>Registration</a>
                        <button type="button" class="btn or-button" translate>OR</button>
                        <a type="button" class="btn btn-lg btn-danger reg-log-button" style="background-color: #db5e70; border-color: #db5e70;"
                         routerLink="/login" translate>Login</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
    </div>
</section>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->