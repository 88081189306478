<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<!-- <div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>{{'About' | translate}}</h2>
                    <ul>
                        <li>
                            <img src="assets/img/spotlight_icon.png" height="20px" alt="Image">
                            <a routerLink="/">{{'Home'| translate}}</a>
                        </li>
                        <li><span>/</span></li>
                        <li>{{'About' | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Page Title -->

<!-- New -->
<div class="new-area pt-100 " style="background-image: url(./assets/img/about/background_about.png); background-size: cover;">
    <div class="container" style="min-width: 100%;">
        <div class="row">
            <div class="col-lg-6 col-md-6 m-0 p-0 align-self-end">
                <div class="new-img mb-0">
                    <img src="assets/img/about/about_us.png" alt="New">
                    <!-- <img src="assets/img/about-shape.png" alt="New"> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6 align-self-baseline">
                <div class="about-content">
                    <div class="section-title">
                        <!-- <span class="sub-title">Explore New Life</span> -->
                        <span class="fs-3 text-uppercase mb-3">Explore New Life</span>
                        <h2 class="text-uppercase" style="color: #481652;">We Are A Team</h2>
                           <h2 class="text-uppercase" style="font-size: 5rem;">Of Experts</h2>
                           <h2 class="text-uppercase" style="color: #481652;">In This Field</h2>
                    </div>
                    <p translate>Spotlight is your ally for impactful influencer campaigns. With content creators ranging from micro to superstar creators, we streamline the discovery and collaboration process with top partners. Whether you're searching on Instagram, YouTube, Facebook, Spotlight assists you in creating exceptional experiences for your team and brand, quickly and efficiently.</p>
                    
                    <p class="text-center pt-5">
                        <svg width="150" height="50" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;"
                        (click)="getStarted()">
                            <rect x="0" y="0" width="150" height="50" rx="25" ry="25" fill="#db5e70" />
                            <text x="50%" y="50%" text-anchor="middle" dy=".3em" fill="#fff" font-size="16" font-family="Arial, sans-serif">
                                <a  class="fw-bolder fs-4">{{'Get Started' |translate}}</a>
                            </text>
                        </svg>
                        <!-- <svg width="150" height="50" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                            <rect x="0" y="0" width="150" height="50" rx="25" ry="25" fill="#db5e70" />
                            <text x="50%" y="50%" text-anchor="middle" dy=".3em" fill="#fff" font-size="16" font-family="Arial, sans-serif">
                                <a routerLink="/" class="fw-bolder fs-4" style="color: #fff;">{{'Get Started' |translate}}</a>
                            </text>
                        </svg> -->
                    </p>

                    <!-- <a class="cmn-btn" routerLink="/">{{'Get Started'| translate}}<i class='bx bx-plus'></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End New -->

<!-- Counter -->
<!-- <div class="counter-area two pt-100">
    <div class="counter-shape"><img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape"></div>

    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-hiring"></i>
                    <p>CANDIDATES</p>
                    <h3><span class="odometer" data-count="87360">00</span> </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-candidate"></i>
                    <p>TOTAL JOBS</p>
                    <h3><span class="odometer" data-count="45257">00</span> </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-working-at-home"></i>
                    <p>EMPLOYERS</p>
                    <h3>
                        <span class="odometer" data-count="9650">00</span> 
                    </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-choice"></i>
                    <p>JOB APPLICATIONS</p>
                    <h3><span class="odometer" data-count="70299">00</span> </h3>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Counter -->

<!-- Work -->
<!-- <section class="work-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Possibilities</span>
            <h2>Our Company Possibilities</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-verify"></i>
                    <span>01</span>
                    <h3>Excellence</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-file"></i>
                    <span>02</span>
                    <h3>Adaptability</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>03</span>
                    <h3>Integrity</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>04</span>
                    <h3>Knowledge</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>05</span>
                    <h3>The Passion</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>06</span>
                    <h3>Innovation</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Work -->

<!-- Explore -->
<!-- <section class="explore-area ptb-100">
    <div class="container">
        <div class="explore-item">
            <div class="section-title">
                <span class="sub-title">Explore New Life</span>
                <h2>Be Found Put Your Cv In Front Of Great Employers</h2>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut tur incidunt laborequaerat voluptatem.</p>
            <ul>
                <li>
                    <a class="left-btn" routerLink="/">Upload Your Resume <i class="flaticon-upload"></i></a>
                </li>
                <li>
                    <span>CEO Message</span>
                    <a class="right-btn popup-youtube" href="https://www.youtube.com/watch?v=07d2dXHYb94&t=88s"><i class='bx bx-play'></i></a>
                </li>
            </ul>
        </div>
    </div>
</section> -->
<!-- End Explore -->

<!-- Candidate -->
<!-- <section class="candidate-area two ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Working Process</span>
            <h2>Leadership Team</h2>
        </div>

        <div class="candidate-slider owl-theme owl-carousel">
            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Arielle Terry</a></h3>
                    <span>Web Developer</span>
                    <p>I’m IRanin dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>Php</li>
                        <li>jQuery</li>
                        <li>WordPress</li>
                        <li>CSS3</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate1.jpg" alt="Candidate">
            </div>

            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Alexander Max</a></h3>
                    <span>Senior UX/UI Designer</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate2.jpg" alt="Candidate">
            </div>

            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Tom Henry</a></h3>
                    <span>SEO</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate3.jpg" alt="Candidate">
            </div>
        </div>
    </div>
</section> -->
<!-- End Candidate -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->