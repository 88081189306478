import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from '../../shared/validations/passwordValidator';
import { AuthApiService } from '../../services/auth-api.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password-company',
  templateUrl: './reset-password-company.component.html',
  styleUrls: ['./reset-password-company.component.scss']
})
export class ResetPasswordCompanyComponent implements OnInit {

  reset_pass_form: FormGroup;
  isNewPasswordText = false
  isNewConfPasswordText = false

  returnUrl: any;
  token: any;  

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authApi : AuthApiService,
    private translate : TranslateService
  ) {
    //Grab query parameters from the URL
    this.token = this.route.snapshot.params['token']
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnInit(): void {
    this.reset_pass_form = this.fb.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'), Validators.maxLength(20)]],
      confirm_password: ['', [Validators.required]]
    },
    {
      validator: MustMatch('password', 'confirm_password')
    } as AbstractControlOptions)
  }

  toggleNewPassword(){
    this.isNewPasswordText = ! this.isNewPasswordText
  }
  toggleNewConfPassword(){
    this.isNewConfPasswordText = ! this.isNewConfPasswordText
  }

  submit() {
    // console.log(this.token);
    this.markFormGroupTouched(this.reset_pass_form);
    if(this.reset_pass_form.invalid){
      return;
    }
    let form_data = new FormData();
    form_data.append('new_password', this.reset_pass_form.get('confirm_password')?.value);
    form_data.append('token', this.token);
    this.authApi.resetCompanyPassword(form_data).subscribe((res : any) =>{
      if (res.status == 'success') {
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        }).then(() => {
          this.router.navigate(['/login'])
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res.message
        })
      }
    })
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
