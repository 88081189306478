import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { AuthApiService } from '../../services/auth-api.service';

@Component({
  selector: 'app-register3',
  templateUrl: './register3.component.html',
  styleUrls: ['./register3.component.scss']
})
export class Register3Component implements OnInit {

  image_file
  image_result
  register3_form: FormGroup;
  influencer_id
  influencer_custom_id;
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.submit();
  }
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private api: AuthApiService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((param) => {
      if(param && param.data) {
        let data = JSON.parse(decodeURIComponent(atob(param.data)))
        // this.influencer_id = data.id
        this.influencer_custom_id = data.custom_id
        // console.log(this.influencer_id)
        // console.log(this.influencer_custom_id)
      } else {
        this.router.navigate(['/register'])
      }
    })
  }

  ngOnInit(): void {
    this.register3_form = this.fb.group({
      identity: ['', [Validators.required, Validators.maxLength(18)]],
      identity_image: ['', [Validators.required]]
    })
  }

  submit() {
    this.markFormGroupTouched(this.register3_form)
    if(this.register3_form.invalid) {
      return;
    }
    // let user_data = JSON.parse(localStorage.getItem('influencer_data'))
    let form_data = new FormData()
    // form_data.append('influencer_custom_id', user_data.custom_id)
    form_data.append('influencer_custom_id', this.influencer_custom_id)
    form_data.append('statistical_id_number', this.register3_form.get('identity').value)
    form_data.append('statistical_id_card', this.image_file, this.image_file.name)
    this.api.register3(form_data).subscribe((res: any) => {
      if(res.status === 'success') {
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        })
        this.router.navigate(['/login'])
        // .then(() => {
        //   this.router.navigate(['/login'])
        // })
      }
    }) 
  }

  getImage(event) {
    if (event.target.files.length > 0) {
      if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png' || event.target.files[0].type == 'application/pdf') {
      }
      else {
        Swal.fire(
        	this.translate.instant('Error!'),
        	this.translate.instant('Accept image only!'),
        	'error'
        );
        this.register3_form.get('influencer_id_card')?.setValue('')
        this.image_result = '';
        return
      }
      const reader = new FileReader();
      reader.onload = e => this.image_result = reader.result;
      reader.readAsDataURL(event.target.files[0]);
      this.image_file = event.target.files[0];
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
