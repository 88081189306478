<!-- Navbar -->
<app-navbar-style-three ></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<!-- <div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2 translate>Contact</h2>
                    <ul>
                        <li>
                            <img src="assets/img/spotlight_icon.png" height="20px" alt="Image">
                            <a routerLink="/" translate>Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li translate>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Page Title -->

<!-- Info -->
<!-- <div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">

            <div class="col-sm-6 col-lg-4">
                <div class="info-item">
                    <i class='bx bx-world'></i>
                    <h3>San Francisco</h3>
                    <p>54 Hegmann Uninuo Apt. 890, New York, NY 10018, United States.</p>
                    <ul>
                        <li><span>Mobile:</span> <a href="tel:+44457895789">(+44) - 45789 - 5789</a></li>
                        <li><span>Email:</span> <a href="mailto:info@alzo.com">info@alzo.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="info-item">
                    <i class='bx bx-world'></i>
                    <h3>India Office</h3>
                    <p>Sco 52, Sector 48, Near Gurudwara Chandigarh (258458)</p>
                    <ul>
                        <li><span>Mobile:</span> <a href="tel:+23456783456">(+234) - 5678 - 3456</a></li>
                        <li><span>Email:</span> <a href="mailto:hello@alzo.com">hello@alzo.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="info-item">
                    <i class='bx bx-world'></i>
                    <h3>UK Office</h3>
                    <p>142 West newton, New York 19088 United States</p>
                    <ul>
                        <li><span>Mobile:</span> <a href="tel:+10009871234">(+1) 000 - 987 - 1234</a></li>
                        <li><span>Email:</span> <a href="mailto:example@alzo.com">example@alzo.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Info -->

<!-- Contact -->
<div class="contact-form-area" >
    <div class="container-md pt-100">
        <div class="form-item">
            <h1 class="mb-3">{{"LET'S KEEP IN TOUCH" |translate}}</h1>

            <form id="contactForm" [formGroup]="contact_form">
                <div class="row">
                    <div class="col-sm-4 col-lg-4">
                        <div class="col">
                            <div class="form-group">
                                <!-- <label>{{'Full Name'| translate}}</label> -->
                                <input type="text" name="name" id="name" placeholder="{{'Full Name'| translate}}" maxlength="25" class="form-control" required formControlName="fullName">
                                <ng-container class="invalid"
                                    *ngIf="contact_form.get('fullName')?.hasError('required') && (contact_form.get('fullName')?.touched || contact_form.get('fullName')?.dirty)">
                                    <small class="text-danger" translate>{{'Please provide full name' |translate}}</small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <!-- <label>{{'Email' |translate}}</label> -->
                                <input type="email" name="email" placeholder="{{'Email'| translate}}" id="email" class="form-control" required formControlName="email">
                                <ng-container class="invalid"
                                    *ngIf="contact_form.get('email')?.hasError('required') && (contact_form.get('email')?.touched || contact_form.get('email')?.dirty)">
                                    <small class="text-danger" translate>{{'Please provide email' |translate}}</small>
                                </ng-container>
                                <ng-container class="invalid"
                                    *ngIf="contact_form.get('email')?.hasError('email') && (contact_form.get('email')?.touched || contact_form.get('email')?.dirty)">
                                    <small class="text-danger" translate>{{'Please provide valid email' |translate}}</small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <!-- <label>{{'Phone Number (Optional)' |translate}}</label> -->
                                <input type="text" name="phone_number" placeholder="{{'Phone Number (Optional)'| translate}}" (keypress)="keyPress($event)" class="form-control"
                                    formControlName="phone_no">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Subject</label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required>
                        </div>
                    </div> -->
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="form-group">
                        <!-- <label>{{'Message'|translate}}</label> -->
                        <textarea name="message" class="form-control" placeholder="{{'Message'| translate}}" maxlength="300"
                            id="message" cols="30" rows="4" required formControlName="message"></textarea>
                        <ng-container class="invalid"
                            *ngIf="contact_form.get('message')?.hasError('required') && (contact_form.get('message')?.touched || contact_form.get('message')?.dirty)">
                            <small class="text-danger" translate>{{'Write down any short message' |translate}}</small>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3">
                    <svg width="150" height="50" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0" y="0" width="150" height="50" rx="25" ry="25" fill="#db5e70" />
                        <text x="50%" y="50%" text-anchor="middle" dy=".3em" fill="#fff" font-size="16" font-family="Arial, sans-serif">
                            <a style="cursor: pointer;" (click)="sendMessage()" class="fw-bolder fs-4">{{'Send' |translate}}</a>
                        </text>
                    </svg>
                    <!-- <button type="submit" class="btn" (click)="sendMessage()">{{'Send Message' |translate}}</button> -->
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Contact -->

<!-- Map -->
<!-- <div class="map-area">
    <div class="container-fluid p-0">
        <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59843174.53779285!2d62.17507173408571!3d23.728204508550363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sbd!4v1599227927146!5m2!1sen!2sbd"></iframe>
    </div>
</div> -->
<!-- End Map -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->