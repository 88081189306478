import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class RedirectAuthGuard {
  constructor(
    private auth: AuthService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.auth.currentUserValue;
    if (user) {
      if (user['role'] == Role.Influencer) {
        this.router.navigate(['/influencer'])
        console.log('influencer route')
      }
      else if (user['role'] == Role.Company) {
        this.router.navigate(['/company'])
        console.log('company route')
      }
      return true;
    }
    console.log('role not found')
    this.router.navigate(['/login'])
    return false
  }
}
