import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { AuthApiService } from '../../services/auth-api.service';

@Component({
  selector: 'app-register2',
  templateUrl: './register2.component.html',
  styleUrls: ['./register2.component.scss']
})
export class Register2Component implements OnInit {

  internet_habit_other
  show_habit = false
  show_social_network = false
  show_passion = false
  show_goal = false

  register_form2: FormGroup
  influencer_id;
  influencer_custom_id;

  computer_1 = this.translate.instant('Do you own a computer?')
  email_2 = this.translate.instant('How often do you check your emails?')
  internet_habit_3 = this.translate.instant('What are your internet habits?')
  social_network_4 = this.translate.instant('What social networks are you on?')
  passion_5 = this.translate.instant('Passion?')
  followers_5 = this.translate.instant('How many people follow you on social media?')
  post_6 = this.translate.instant('How often do you post?')
  goal_7 = this.translate.instant('Your goal?')
  behave_8 = this.translate.instant('Is it important for you to behave ethically towards your friends/followers on social networks?')
  ethical_9 = this.translate.instant('According to you, what are the 3 most important elements to be ethical with your friends/followers on your social networks?')
  trust_10 = this.translate.instant('In your opinion, how much trust do your friends/followers have in you on social networks?')
  opinions_11 = this.translate.instant('Do you think it is important for your friends/followers that you share your opinions on social media?')
  brand_commitment_12 = this.translate.instant('Do you think it is important for a brand to share more information with you about their commitments ahead of a product test?')

  internet_habits = [
    { option: this.translate.instant('News'), checked: false },
    { option: this.translate.instant('Social Networks'), checked: false },
    { option: this.translate.instant('Blog'), checked: false },
    { option: this.translate.instant('Film'), checked: false },
    { option: this.translate.instant('Games'), checked: false },
    { option: this.translate.instant('Entertainment'), checked: false },
    { option: this.translate.instant('Communication'), checked: false },
    { option: this.translate.instant('Other'), checked: false },
  ]

  social_networks = [
    { option: 'Facebook', checked: false },
    { option: 'Twitter', checked: false },
    { option: 'Instagram', checked: false },
    { option: 'Behance', checked: false },
    { option: 'Pinterest', checked: false },
    { option: 'Linkedin', checked: false },
    { option: 'TikTok', checked: false },
    { option: this.translate.instant('Other'), checked: false },
  ]

  passion = [
    { option: this.translate.instant('Cinema'), checked: false },
    { option: this.translate.instant('Music'), checked: false },
    { option: this.translate.instant('Lifestyle'), checked: false },
    { option: this.translate.instant('Food'), checked: false },
    { option: this.translate.instant('Entertainment'), checked: false },
    { option: this.translate.instant('Individual sport'), checked: false },
    { option: this.translate.instant('Team sport'), checked: false },
    { option: this.translate.instant('Other'), checked: false },
  ]

  goal = [
    { option: this.translate.instant('Influencing and communicating for brands'), checked: false },
    { option: this.translate.instant('Create content and dedicate some for brands'), checked: false },
    { option: this.translate.instant('Increase my number of subscribers on my social networks'), checked: false },
    { option: this.translate.instant('Other'), checked: false },
  ]

  ethical_opinion = [
    { option: this.translate.instant('Do not peddle fake news'), checked: false },
    { option: this.translate.instant('Be kind to your friends/followers'), checked: false },
    { option: this.translate.instant('Instead, highlight committed brands'), checked: false },
    { option: this.translate.instant('Support a cause (NGO / Associations / etc)'), checked: false },
    { option: this.translate.instant('Be convinced yourself by the product or brand you mention'), checked: false },
    { option: this.translate.instant('Authentically explain your opinion on a product, a brand'), checked: false },
  ]

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private api: AuthApiService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((param) => {
      if(param && param.data) {
        let data = JSON.parse(decodeURIComponent(atob(param.data)))
        // this.influencer_id = data.id
        this.influencer_custom_id = data.custom_id
        // console.log(this.influencer_id)
        // console.log(this.influencer_custom_id)
      } else {
        this.router.navigate(['/register'])
      }
    })
  }

  ngOnInit(): void {
    this.register_form2 = this.fb.group({
      computer: ['', [Validators.required]],
      check_email: ['', [Validators.required]],
      followers: ['', [Validators.required]],
      post: ['', [Validators.required]],
      behaviour: ['', [Validators.required]],
      trust: ['', [Validators.required]],
      opinion: ['', [Validators.required]],
      brand_commitment: ['', [Validators.required]],
      internet_habit: ['', ''],
      social_network: ['', ''],
      passion: ['', ''],
      goal: ['', ''],
    })
  }

  habit_click(index) {
    if(index == 7) {
      if(this.internet_habits[7].checked == false) {
        console.log('other checked')
        this.show_habit = true
        this.register_form2.get('internet_habit').setValidators(Validators.required);
        this.register_form2.get('internet_habit').updateValueAndValidity;
      }else {
        this.show_habit = false
        this.register_form2.get('internet_habit').clearValidators;
        this.register_form2.get('internet_habit').updateValueAndValidity;
        this.register_form2.get('internet_habit').setValue('null')
        console.log('other not checked')
      }
    }
  }

  social_network_click(index) {
    if(index == 7) {
      if(this.social_networks[7].checked == false) {
        this.show_social_network = true
        console.log('other checked')
        this.register_form2.get('social_network').setValidators(Validators.required);
        this.register_form2.get('social_network').updateValueAndValidity;
      }else {
        this.show_social_network = false
        console.log('other unchecked')
        this.register_form2.get('social_network').clearValidators;
        this.register_form2.get('social_network').updateValueAndValidity;
        this.register_form2.get('social_network').setValue('null')
      }
    }
  }

  passion_click(index) {
    if(index == 7) {
      if(this.passion[7].checked == false) {
        this.show_passion = true
        this.register_form2.get('passion').setValidators(Validators.required);
        this.register_form2.get('passion').updateValueAndValidity;
      }else {
        this.show_passion = false
        this.register_form2.get('passion').clearValidators;
        this.register_form2.get('passion').updateValueAndValidity;
        this.register_form2.get('passion').setValue('null')
      }
    }
  }

  goal_click(index) {
    if(index == 3) {
      if(this.goal[3].checked == false) {
        this.show_goal = true
        this.register_form2.get('goal').setValidators(Validators.required);
        this.register_form2.get('goal').updateValueAndValidity;
      }else {
        this.show_goal = false
        this.register_form2.get('goal').clearValidators;
        this.register_form2.get('goal').updateValueAndValidity;
        this.register_form2.get('goal').setValue('null')
      }
    }
  }

  submit() {
    // this.router.navigate(['/register-step3'])
    let selected_internet_habit = this.internet_habits.filter(habits => habits.checked == true).map(habit => habit.option)
    let selected_social_network = this.social_networks.filter(habits => habits.checked == true).map(habit => habit.option)
    let selected_passion = this.passion.filter(habits => habits.checked == true).map(habit => habit.option)
    let selected_goal = this.goal.filter(habits => habits.checked == true).map(habit => habit.option)
    let selected_ethical = this.ethical_opinion.filter(habits => habits.checked == true).map(habit => habit.option)
    let form_json = [
      {
        "id": "1",
        "quetion": this.computer_1,
        "answer": this.register_form2.get('computer').value,
        "type": "single",
        "text_field": ""
      },
      {
        "id": "2",
        "quetion": this.email_2,
        "answer": this.register_form2.get('check_email').value,
        "type": "single",
        "text_field": ""
      },
      {
        "id": "3",
        "quetion": this.internet_habit_3,
        "answer": selected_internet_habit,
        "type": "multiple",
        "text_field": this.register_form2.get('internet_habit').value
      },
      {
        "id": "4",
        "quetion": this.social_network_4,
        "answer": selected_social_network,
        "type": "multiple",
        "text_field": this.register_form2.get('social_network').value
      },
      {
        "id": "5",
        "quetion": this.passion_5,
        "answer": selected_passion,
        "type": "multiple",
        "text_field": this.register_form2.get('passion').value
      },
      {
        "id": "6",
        "quetion": this.post_6,
        "answer": this.register_form2.get('post').value,
        "type": "single",
        "text_field": ""
      },
      {
        "id": "7",
        "quetion": this.goal_7,
        "answer": selected_goal,
        "type": "multiple",
        "text_field": this.register_form2.get('goal').value
      },
      {
        "id": "8",
        "quetion": this.behave_8,
        "answer": this.register_form2.get('behaviour').value,
        "type": "single",
        "text_field": ""
      },
      {
        "id": "9",
        "quetion": this.ethical_9,
        "answer": selected_ethical,
        "type": "multiple",
        "text_field": ""
      },
      {
        "id": "10",
        "quetion": this.trust_10,
        "answer": this.register_form2.get('trust').value,
        "type": "single",
        "text_field": ""
      },
      {
        "id": "11",
        "quetion": this.opinions_11,
        "answer": this.register_form2.get('opinion').value,
        "type": "single",
        "text_field": ""
      },
      {
        "id": "12",
        "quetion": this.brand_commitment_12,
        "answer": this.register_form2.get('brand_commitment').value,
        "type": "single",
        "text_field": ""
      },
    ]
    this.markFormGroupTouched(this.register_form2)
    if(this.register_form2.invalid) {
      console.log('form invalid')
      return;
    }
    if(selected_internet_habit.length < 1 || selected_social_network.length < 1 || selected_passion.length < 1
      || selected_goal.length < 1 || selected_ethical.length < 1) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Toast.fire({
        icon: 'error',
        text: this.translate.instant('All the questions are required')
      })
      return;
    }
    // let user_data = JSON.parse(localStorage.getItem('influencer_data'))
    let form_data = new FormData()
    // form_data.append('influencer_custom_id', user_data.custom_id)
    form_data.append('influencer_custom_id', this.influencer_custom_id)
    form_data.append('questionnaire', JSON.stringify(form_json))
    // console.log(JSON.stringify(form_json))
    this.api.register2(form_data).subscribe((res: any) => {
      if(res.status == 'success') {
        let param = btoa(encodeURIComponent(JSON.stringify({'custom_id': this.influencer_custom_id})))
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        })
        this.router.navigate(['/register-step3'], {queryParams: {data: param}})
        // .then(() => {
        //   this.router.navigate(['/register-step3'], {queryParams: {data: param}})
        // })
      }
    })
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
