<!-- Register -->
<div class="user-form-area form-are2" style="background-image: url('assets/img/login_bg.png'); background-size: cover;">
<!-- <div class="user-form-area"> -->
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img2">
                    <img src="assets/img/registration.png" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12" >
                <div class="user-content">
                    <div class="top">
                        <ng-container *ngIf="formOpen == false">
                            <h2 translate>Register Here</h2>
                        </ng-container>
                        <ng-container *ngIf="is_influencer == true">
                            <h2 translate>Influencer Registration</h2>
                        </ng-container>
                        <ng-container *ngIf="is_company== true">
                            <h2 translate>Company Registration</h2>
                        </ng-container>
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <a class="btn btn-sm fs-5"
                                    [ngClass]="{'reg-button': is_influencer, 'normal-button': !is_influencer}"
                                    (click)="influencer_click()" translate>As Influencer</a>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <a class="btn btn-sm reg-button company fs-5"
                                    [ngClass]="{'reg-button': is_company, 'normal-button': !is_company}"
                                    (click)="company_click()" translate>As Company</a>
                            </div>
                        </div>
                        <ng-container *ngIf="formOpen == true">
                            <form [formGroup]="register_form" class="mt-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="{{'First Name' | translate}}"
                                        formControlName="first_name">
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('first_name')?.hasError('required') && (register_form.get('first_name')?.touched || register_form.get('first_name')?.dirty)">
                                        <small class="text-danger" translate>Please provide first name</small>
                                    </ng-container>
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('first_name')?.hasError('maxlength') && (register_form.get('first_name')?.touched || register_form.get('first_name')?.dirty)">
                                        <small class="text-danger" translate>Maximum 120 characters allowed</small>
                                    </ng-container>
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('first_name')?.hasError('pattern') && (register_form.get('first_name')?.touched || register_form.get('first_name')?.dirty)">
                                        <small class="text-danger" translate>Accept characters only</small>
                                    </ng-container>
                                </div>
    
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="{{'Last Name' | translate}}"
                                        formControlName="last_name">
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('last_name')?.hasError('required') && (register_form.get('last_name')?.touched || register_form.get('last_name')?.dirty)">
                                        <small class="text-danger" translate>Please provide last name</small>
                                    </ng-container>
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('last_name')?.hasError('maxlength') && (register_form.get('last_name')?.touched || register_form.get('last_name')?.dirty)">
                                        <small class="text-danger" translate>Maximum 120 characters allowed</small>
                                    </ng-container>
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('last_name')?.hasError('pattern') && (register_form.get('last_name')?.touched || register_form.get('last_name')?.dirty)">
                                        <small class="text-danger" translate>Accept characters only</small>
                                    </ng-container>
                                </div>
    
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="{{'Email' | translate}}" formControlName="email">
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('email')?.hasError('required') && (register_form.get('email')?.touched || register_form.get('email')?.dirty)">
                                        <small class="text-danger" translate>Please provide email</small>
                                    </ng-container>
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('email')?.hasError('email') && (register_form.get('email')?.touched || register_form.get('email')?.dirty)">
                                        <small class="text-danger" translate>Please provide valid email</small>
                                    </ng-container>
                                </div>
    
                                <div class="form-group">
                                    <input type="tel" class="form-control" placeholder="{{'Mobile Number' | translate}}"
                                        formControlName="mobile" maxlength="16" type="tel" minlength="6"
                                        (keypress)="keyPress($event)">
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('mobile')?.hasError('required') && (register_form.get('mobile')?.touched || register_form.get('mobile')?.dirty)">
                                        <small class="text-danger" translate>Please provide mobile number</small>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="is_influencer == true">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="{{'Social Media Link' | translate}}"
                                            formControlName="social_link">
                                        <ng-container class="invalid"
                                            *ngIf="register_form.get('social_link')?.hasError('required') && (register_form.get('social_link')?.touched || register_form.get('social_link')?.dirty)">
                                            <small class="text-danger" translate>Please provide social media link</small>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="is_company == true">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="{{'Position' | translate}}"
                                            formControlName="position">
                                        <ng-container class="invalid"
                                            *ngIf="register_form.get('position')?.hasError('required') && (register_form.get('position')?.touched || register_form.get('position')?.dirty)">
                                            <small class="text-danger" translate>Please provide position</small>
                                        </ng-container>
                                        <ng-container class="invalid"
                                            *ngIf="register_form.get('position')?.hasError('maxlength') && (register_form.get('position')?.touched || register_form.get('position')?.dirty)">
                                            <small class="text-danger" translate>Maximum 120 characters allowed</small>
                                        </ng-container>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="{{'Company' | translate}}"
                                            formControlName="company">
                                        <ng-container class="invalid"
                                            *ngIf="register_form.get('company')?.hasError('required') && (register_form.get('company')?.touched || register_form.get('company')?.dirty)">
                                            <small class="text-danger" translate>Please provide company</small>
                                        </ng-container>
                                        <ng-container class="invalid"
                                            *ngIf="register_form.get('company')?.hasError('maxlength') && (register_form.get('company')?.touched || register_form.get('company')?.dirty)">
                                            <small class="text-danger" translate>Maximum 120 characters allowed</small>
                                        </ng-container>
                                    </div>
                                </ng-container>
    
                                <div class="form-group">
                                    <div class="input-group">
                                        <input [type]="isNewPasswordText ? 'text' : 'password'" name="password" formControlName="password"
                                            class="form-control" placeholder="{{'Password' | translate}}"
                                            [ngClass]="{'has-success': register_form.get('password')?.valid}">
                                        <div class="input-group-text">
                                            <a class="link-icon" (click)="toggleNewPassword()" style="cursor: pointer; color: black;"><i class="bx"
                                                    [ngClass]="!isNewPasswordText ? 'bx-show' : 'bx-hide'"></i></a>
                                        </div>
                                    </div>
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('password')?.hasError('required') && (register_form.get('password')?.touched || register_form.get('password')?.dirty)">
                                        <small class="text-danger" translate>Please provide password</small>
                                    </ng-container>
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('password')?.hasError('pattern') && (register_form.get('password')?.touched || register_form.get('password')?.dirty)">
                                        <small class="text-danger" translate>Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character</small>
                                    </ng-container>
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('password')?.hasError('maxlength') && (register_form.get('password')?.touched || register_form.get('password')?.dirty)">
                                        <small class="text-danger" translate>Password is too long</small>
                                    </ng-container>
                                </div>
    
                                <div class="form-group">
                                    <div class="input-group">
                                        <input [type]="isNewConfPasswordText ? 'text' : 'password'" name="confirm_password" formControlName="confirm_password"
                                            class="form-control" placeholder="{{'Confirm Password' | translate}}"
                                            [ngClass]="{'has-success': register_form.get('confirm_password')?.valid}">
                                        <div class="input-group-text">
                                            <a class="link-icon" (click)="toggleNewConfPassword()" style="cursor: pointer; color: black;"><i class="bx"
                                                    [ngClass]="!isNewConfPasswordText ? 'bx-show' : 'bx-hide'"></i></a>
                                        </div>
                                    </div>
                                    <!-- <input type="password" class="form-control" placeholder="Confirm Password"
                                        formControlName="confirm_password"> -->
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('confirm_password')?.hasError('required') && (register_form.get('confirm_password')?.touched || register_form.get('confirm_password')?.dirty)">
                                        <small class="text-danger" translate>Please confirm password</small>
                                    </ng-container>
                                    <ng-container class="invalid"
                                        *ngIf="register_form.get('confirm_password')?.hasError('mustMatch') && (register_form.get('confirm_password')?.touched || register_form.get('confirm_password')?.dirty)">
                                        <small class="text-danger" translate>Password doesn't match</small>
                                    </ng-container>
                                </div>
    
                                <div class="form-group form-check">
                                    <div class="checkbox checkbox-primary d-inline">
                                        <input type="checkbox" formControlName="terms_condition" class="form-check-input"
                                            id="termsCheck59">
                                        <label class="form-check-label fs-6" for="termsCheck59" translate>I agree with the <a routerLink="/terms-conditions">Terms & Conditions</a> and <a routerLink="/terms-conditions">Privacy Policy</a> of SPOTLIGHT</label>
                                        <ng-container class="invalid"
                                            *ngIf="register_form.get('terms_condition')?.hasError('required') && (register_form.get('terms_condition')?.touched || register_form.get('terms_condition')?.dirty)">
                                            <small class="text-danger" translate>Please accept terms & conditions</small>
                                        </ng-container>
                                    </div>
                                </div>
    
                                <button *ngIf="is_influencer == true" type="button" class="btn fs-5" (click)="influencer_submit()" translate>Submit</button>
                                <button *ngIf="is_company == true" type="button" class="btn fs-5" (click)="company_submit()" translate>Submit</button>
                                <div class="row mt-2">
                                    <div class="col-md-6">
                                        <a class="forget-button float-start" routerLink="/" translate>Go to Home</a>
                                    </div>
                                    <div class="col-md-6">
                                        <!-- <a class="forget-button float-end" routerLink="/forgot-company-pass">Forgot password?</a> -->
                                    </div>
                                </div>
                            </form>
                        </ng-container>
                        
                    </div>

                    <!-- <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Register -->