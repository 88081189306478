<!-- Footer -->
<footer class="footer-area pt-50 pb-70">
    <div class="container">
        <div class="row pt-100">
            <div class="col-sm-6 col-lg-4 custom-line">
                <div class="footer-item ">
                    <div class="footer-logo">
                        <!-- <a class="logo" routerLink="/"><img src="assets/img/logo-three.png" alt="Logo"></a> -->
                        <a class="logo" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
                        <ul>
                            <li>
                                <h3>{{"The future already started" | uppercase | translate}}</h3>
                            </li>
                            <li><span>&copy;&nbsp;2023. {{"Spotlight" | uppercase | translate}} <br>{{"All Rights Reserved" | titlecase | translate}}.</span></li>
                        </ul>
                        <!-- <ul>
                            <li><span>Address: </span>2659 Autostrad St, London, UK</li>
                            <li><span>Message: </span><a href="mailto:hello@alzo.com">hello@alzo.com</a></li>
                            <li><span>Phone: </span><a href="tel:2151234567">215 - 123 - 4567</a></li>
                            <li><span>Open: </span>Mon - Fri / 9:00 AM - 6:00 PM</li>
                        </ul> -->
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-6 col-lg-3 custom-line_md">
                <div class="footer-item ">
                    <div class="footer-service">
                        <h3 class="">{{'Contact' |translate}}</h3>
                        <ul>
                            <li>
                                <i class="bx bx-location-plus" style="font-size: 30px; color: #221668;"></i>
                                <div class="fleft">
                                    <a href="">{{"lorem ipsum est un, Hydra; Alger" | translate}}</a>
                                </div>
                            </li>
                            <li class="cust_li">
                                <i class="bx bx-envelope" style="font-size: 30px; color:#221668;"></i>
                                <div class="fleft">
                                    <a href="mailto:hello@spotlight-dz.com">{{"hello@spotlight-dz.com"}}</a>
                                </div>
                            </li>
                            <li>
                                <i class="bx bx-phone" style="font-size: 30px; color:#221668;"></i>
                                <div class="fleft">
                                    <a href="tel:(+213) 000 000 000">{{'(+213) 000 000 000'}}</a>
                                </div>
                            </li>
                            <li><a routerLink="/jobs">Teachers</a></li>
                            <li><a routerLink="/jobs">Customer Service</a></li>
                            <li><a routerLink="/jobs">Digital Marketing</a></li>
                            <li><a routerLink="/jobs">Web & Software Dev</a></li>
                            <li><a routerLink="/jobs">Science & Analytics</a></li>
                        </ul>
                    </div>
                </div>
            </div> -->

            <div class="col-sm-6 col-lg-4 custom-line">
                <div class="footer-item ">
                    <div class="footer-service">
                        <h3>{{'Useful Links' | translate}}</h3>

                        <ul >
                            <li><a routerLink="/terms-conditions">{{'Terms & Conditions' | translate}}</a></li>
                            <li ><a routerLink="/terms-conditions">{{'Privacy Policy' | translate}}</a></li>
                            <li ><a routerLink="/about">{{'About' | translate}}</a></li>
                            <li><a routerLink="/faq">{{'FAQs' | translate}}</a></li>
                            <!-- <li><a routerLink="/candidates">Candidates</a></li>
                            <li><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/contact">Contact</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="footer-item ">
                    <div class="footer-service">
                        <h3>{{'Social Media' | translate}}</h3>
                            <ul class="cust-footer-social-icon" style="display: flex !important;">
                                <li style="margin-right: 10px;">
                                    <a target="_blank" title="Connect on Instagram" class="footer-icon" href="
                                    https://www.instagram.com/spotlight_dz/">
                                        <img src="assets/img/insta_icon.svg" />
                                    </a>
                                </li>
                                <li style="margin-right: 10px;">
                                    <a target="_blank" title="Connect on Twitter" class="footer-icon" href="https://www.facebook.com/profile.php?id=100069632199780">
                                        <img src="assets/img/facebook_icon.svg" />
                                    </a>
                                </li>
                                <li style="margin-right: 10px;">
                                    <a target="_blank" title="Connect on Tiktok" class="footer-icon" href="https://www.tiktok.com/@spotlight.dz">
                                        <img src="assets/img/tiktok_icon.svg" />
                                    </a>
                                </li>
                                <li style="margin-right: 10px;">
                                    <a target="_blank" title="Connect on LinkedIn" class="footer-icon" href="https://www.linkedin.com/company/spotlightdz/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BB9%2F2z1WuQi%2BIae9Pq30%2FPw%3D%3D">
                                        <img src="assets/img/linkedin_icon.svg" />
                                    </a>
                                </li>
                            </ul>
                        <!-- <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed</p>
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Your email*" name="EMAIL" required autocomplete="off">
                            <button class="btn" type="submit"><i class="flaticon-send"></i></button>
                        </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->

<!-- Copyright -->
<!-- <div class="copyright-area three">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin-square'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <p>© Alzo is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Copyright -->