<!-- Login -->
<!-- <div class="user-form-area form-are2" style="background-image: url('assets/img/login_bg.jpg');"> -->
<div class="user-form-area" style="background-image: url('assets/img/login_bg.png'); background-size: cover; min-height: 100vh;" >
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="mt-5 ms-5">
                    <img src="./assets/img/registration.png" alt="User">
                </div>
            </div>
            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <ng-container *ngIf="formOpen == false">
                            <h2 translate>Login Here</h2>
                        </ng-container>
                        <ng-container *ngIf="is_influencer == true">
                            <h2 translate>Influencer Login</h2>
                        </ng-container>
                        <ng-container *ngIf="is_company== true">
                            <h2 translate>Company Login</h2>
                        </ng-container>
                        <!-- toggle button -->
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <a class="btn btn-sm  fs-5"
                                    [ngClass]="{'reg-button': is_influencer, 'normal-button': !is_influencer}"
                                    (click)="influencer_click()" translate>As Influencer</a>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <a class="btn btn-sm reg-button company fs-5"
                                    [ngClass]="{'reg-button': is_company, 'normal-button': !is_company}"
                                    (click)="company_click()" translate>As Company</a>
                            </div>
                        </div>
                        <!-- form -->
                        <ng-container *ngIf="formOpen == true">
                            <form class="mt-3" [formGroup]="login_form" >
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="{{'Email' | translate}}" formControlName="email">
                                    <ng-container class="invalid"
                                        *ngIf="login_form.get('email')?.hasError('required') && (login_form.get('email')?.touched || login_form.get('email')?.dirty)">
                                        <small class="text-danger" translate>Please provide email</small>
                                    </ng-container>
                                    <ng-container class="invalid"
                                        *ngIf="login_form.get('email')?.hasError('email') && (login_form.get('email')?.touched || login_form.get('email')?.dirty)">
                                        <small class="text-danger" translate>Please provide valid email</small>
                                    </ng-container>
                                </div>
    
                                <div class="form-group">
                                        <div class="input-group">
                                            <input [type]="isNewPasswordText ? 'text' : 'password'" name="password" formControlName="password"
                                                class="form-control" placeholder="{{'Password' | translate}}"
                                                [ngClass]="{'has-success': login_form.get('password')?.valid}">
                                            <div class="input-group-text">
                                                <a class="link-icon" (click)="toggleNewPassword()" style="cursor: pointer; color: black;"><i class="bx"
                                                        [ngClass]="!isNewPasswordText ? 'bx-show' : 'bx-hide'"></i></a>
                                            </div>
                                        </div>
                                    <ng-container class="invalid"
                                        *ngIf="login_form.get('password')?.hasError('required') && (login_form.get('password')?.touched || login_form.get('password')?.dirty)">
                                        <small class="text-danger" translate>Please provide password</small>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="is_influencer == true">
                                    <button type="button" (click)="onInfluencerLogin()" class="btn mb-2 fs-6" translate>Login Here</button>
                                    <a class="forget-button float-start" routerLink="/" translate>Go to Home</a>
                                    <a class="forget-button float-end" routerLink="/forgot-influencer-pass" translate>Forgot Password?</a>
                                    <div class="row mt-5">
                                        <div class="col-md-12">
                                            <p translate>You don’t have an account yet ?
                                                <a class="forget-button " routerLink="/register" translate>Signup</a> {{'here' |translate}}
                                            </p>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="is_company == true">
                                    <button type="button" (click)="onCompanyLogin()" class="btn mb-2" translate>Login Here</button>
                                    <a class="forget-button float-start" routerLink="/" translate>Go to Home</a>
                                    <a class="forget-button float-end" routerLink="/forgot-company-pass" translate>Forgot Password?</a>
                                    <div class="row mt-5">
                                        <div class="col-md-12">
                                            <p translate>You don’t have an account yet ?
                                                <a class="forget-button " routerLink="/register" translate>Signup</a> {{'here' |translate}}
                                            </p>
                                        </div>
                                    </div>
                                </ng-container>
                            </form>
                        </ng-container>
                        
                    </div>

                    <!-- <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->