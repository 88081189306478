<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="title-item">
          <h2>{{'Terms & Conditions' | translate}}</h2>
          <ul>
            <li>
              <img src="assets/img/spotlight_icon.png" height="20px" alt="Image">
              <a routerLink="/">{{'Home' |translate}}</a>
            </li>
            <li><span>/</span></li>
            <li>{{'Terms & Conditions' |translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Page Title -->

<!-- Terms & Conditions -->
<!-- <div class="rules-area pt-100"> -->
<div class="pt-100">
  <div class="container">
    <div class="rules-item">
      <h2>{{"Article 1: Purpose of the General Conditions of Sale" | translate}}</h2>
      <p>{{"The purpose of the General Terms and Conditions of Sale is to define the conditions under which the company EURL Spotlight, whose registered office is located in the Cité petite province, No. 04 Paradou Niv 01 Hydra - ALGER, spotlightdz.dz service; or the teams in charge of the spotlightdz.dz site designated indistinctly hereinafter by 'Spotlight' provides to any legal or natural person having created a Brand account and/or Communication Agency (designated hereinafter by the 'Customer')." | translate}}</p>
      <p>{{"the services offered by Spotlight that are detailed on the website accessible at:spotlightdz.dz, including the implementation of advertising campaigns by one or more creators of content chosen by the Customer referred to indistinctly below by 'the creator', which the Client acknowledges having read before committing." | translate}}</p>
      <p>{{"They apply without any restriction or reservation to all services provided by Spotlight or to Customers, regardless of the clauses that may appear on the Customers' documents, including any general terms and conditions of purchase." | translate}}</p>

      <h2>{{"Article 2: Spotlight terms of use and relationship between the Customer and the Content creator" | translate}}</h2>
      <p>{{"In order to carry out the projects, the Client must publish precise and exhaustive campaign guidelines on the Spotlight site (hereinafter referred to as the “Brief”), so that the creator has all the elements available to best meet the campaign project defined in the Brief." | translate}}</p>
      <p>{{"For paid campaigns, the content creator is paid:" | translate}} <br>
        {{"- Directly and only by SPOTLIGHT." | translate}}
      </p>
      <p>{{"The remuneration of the content creator is made in proportion to the compliance with the brief as estimated and defined by the Customer by adding a commission that is set at 9% on the amount excluding VAT on behalf of Spotlight. In case of dispute by the content creator of this prorata, Spotlight reserves the right to decide and indicate a prorata which will then be considered validated by the parties." | translate}}</p>
      <p>{{"For non-paying campaigns, the costs related to logistics will be defined by the manager." | translate}}</p>
      <h3>{{"2.1 Choice of content creator" | translate}}</h3>
      <p>{{"The Client has the right to choose among the content creators who applied to the campaign accordance with the regulations in force, these General Conditions of Sale are systematically communicated to any Customer who makes the request, to allow him to place an order with the Spotlight website." | translate}}</p>
      <p>{{"Any registration and request for service or subscription implies on the part of the Customer and the creator of content, acceptance of these General Conditions of Sale and the general conditions of use of the Spotlight website." | translate}}</p>
      <p>{{"These General Conditions of Quotation are communicated within 72 (seventy-two hours) to any Customer who requests it." | translate}}</p>

      <h3>{{"2.2 Submission of the Campaign Brief" | translate}}</h3>
      <p>{{"The Brief is defined by the Client." | translate}}</p>
      <p>{{"It is mandatory that the brief is validated by Spotlight beforehand." | translate}}</p>
      <p>{{"Spotlight reserves the right to refuse any Brief it deems non-compliant or irrelevant to content creators." | translate}}</p>
      <p>{{"Spotlight reserves the right to accept or refuse campaigns from any Client that the spotlight teams deem non-compliant." | translate}}</p>
      <p>{{"- Sites or products deemed not qualitative by Spotlight teams may be refused." | translate}}</p>

      <h3>{{"2.3 Modifying the Campaign Brief" | translate}}</h3>
      <p>{{"The initial Brief of a content creator, namely a content creator whose participation in the campaign has been validated by the Customer cannot be modified by the content creator." | translate}}</p>
      <p>{{"Any modification of the Brief by the Client is applicable to any content creator whose participation in the campaign has been validated by the Client." | translate}}</p>

      <h3>{{"2.4 Campaign Delivery by Content Creator" | translate}}</h3>
      <p>{{"The content creator has freely the choice to apply or not to the campaign proposed by the Customer." | translate}} </p>
      <p>{{"- The content creator acknowledges having taken into consideration the Brief and the remuneration of the campaign when submitting the application to participate in the Client’s campaign." | translate}}</p>
      <p>{{"- The content creator undertakes to execute the campaign brief well and respect the dates and times of the posts that are set by the Customer." | translate}}</p>
      <p>{{"If the campaign is not completed by the content creator, the related campaign portion will not be due by the brand or spotlight." | translate}}</p>
      <p>{{"- If the content creator respects only part of the Brief when the campaign is carried out, the part of the campaign relating to that creator will have to be paid in proportion to the respect of the brief." | translate}}</p>
      <p>{{"- If the campaign is not carried out on time, the content creator has the right to terminate the contract with the Customer one (1) month after the date of launch planned and fixed by the Customer." | translate}}</p>
      <p>{{"- The content creator is completely free artistically, including in particular in the speech he can have about the Customer, its products or services respecting the brief and the artistic direction of the campaign." | translate}}</p>

      <h3>{{"2.5 Validation of publications" | translate}}</h3>
      <p>{{"The Client expressly acknowledges the independence and artistic freedom of the content creator and cannot refuse the publication of a Post that respects the published Brief of its campaign." | translate}}</p>
      <p>{{"The Client has seventy-two (72) hours to validate or refuse the publication only if the publication does not comply with the Brief." | translate}}</p>
      <p>{{"In the absence of validation or refusal by the Customer within this 72-hour period, the publication is considered validated without reservation or restriction or possible modification by the Customer. The Client must rate the publications made as part of his campaign in an ethical and respectful manner in order to respect the service provided by the content creator and his artistic freedom." | translate}}</p>

      <h3>{{"2.6 Discussion with the Content Creator" | translate}}</h3>
      <p>{{"Under no circumstances may the Client negotiate, propose, suggest any other tariff or tariff condition in any form, up or down, to the creator of content directly or indirectly by a third party. Spotlight reserves the right to block any message or account that violates these rules and to engage the contractual responsibility of the Customer in this regard." | translate}}</p>
      
      <h3>{{"2.7 Transparency and Ethics" | translate}}</h3>
      <p>{{"The content creator must respect the legislation, rules and standards in force throughout the duration of the collaboration with the Customer in the context of the campaign." | translate}}</p>
      <p>{{"The content creator must clearly, transparently and unambiguously indicate throughout the promotion, directly on the image and video in its publications within its network and community that its publication is made as part of a paid or unpaid collaboration with the Client." | translate}}</p>
      <p>{{"The indication of the collaboration must be made with the mention 'advertising' or 'commercial collaboration'" | translate}}</p>
      <p>{{"Any breach of this obligation to publish and verify these mentions respectively by the content creator and by the Customer entails their full and complete responsibility and releases Spotlight or its responsibility." | translate}}</p>
      <p>{{"The Customer and the Content Creator expressly guarantee Spotlight against all financial consequences, image damage, fees, losses, convictions etc., including attorney’s fees, that Spotlight or would have to suffer as a result of such a breach by the Customer or the creator of content of their obligations of transparency and ethics." | translate}}</p>

      <h3>{{"2.8 Hearing Action" | translate}}</h3>
      <p>{{"The Customer acknowledges that the audiences of the Content Creator are provided by Spotlight or the Content Creator for informational purposes only and does not constitute a similar audience guarantee during the Customer’s promotional campaign." | translate}}</p>
      <p>{{"The Customer expressly acknowledges that it cannot engage the responsibility of Spotlight or on the basis of a decrease in the audience or the number of views of the publications during its campaign." | translate}}</p>

      <h3>{{"2.9 Assignment of intellectual property rights and image rights" | translate}}</h3>
      <p>{{"Except with the express prior consent of the Content Creator in connection with the Campaign, the Customer has no rights to the intellectual property rights and image rights of the Content Creator outside the duration of the Campaign." | translate}}</p>
      <p>{{"If the Customer has the agreement of the content creator via the Spotlight agency, then he may reuse the content made by the content creator as part of the type of use, scope of use, duration of use, geographical perimeter) that it will establish for each campaign." | translate}}</p>
      <p>{{"The Customer acknowledges that the reuse of content made by the content creator outside the duration of the campaign requires a second commercial offer that is communicated by Spotlight." | translate}}</p>

      <h3>{{"2.10 References" | translate}}</h3>
      <p>{{"The Customer authorizes Spotlight to quote:" | translate}}</p>
      <p>{{"the elements, including, in particular, without being exhaustive, the name, logo, or any other graphic, sound or other element of a nature to identify the Client and for reference purposes;" | translate}}</p>
      <p>{{"on its own communication media, including but not limited to its website or social networks." | translate}}</p>
      
      <h3>{{"Article 3: Financial arrangements" | translate}}</h3>
      <p>{{"Invoices are issued upon receipt of the transfer order from the Customer." | translate}}</p>
      <p>{{"The amounts are expressed and payable in VAT and Algerian dinars (DZD)." | translate}}</p>
      <p>{{"Invoices are identified on behalf of Spotlight and include all logistics expenses related to the campaign." | translate}}</p>
      <p>{{"With the exception of other terms defined by the employee in charge of the commercial relationship with the Customer, the Customer’s payments are made by bank transfer automatically, as follows:" | translate}}</p>
      <p>{{"100% of the amount including VAT on receipt of the purchase order." | translate}}</p>
      <p>{{"The customer must make sure to have a permanent valid means of payment." | translate}}</p>
      <p>{{"Any default or delay in payment by the customer will have an impact on the immediate launch or suspension of the Spotlight platform services." | translate}}</p>
      <p>{{"With the exception of other terms defined by the employee in charge of the commercial relationship with the content creator, the payments of the content creator are made by bank transfer thirty (30) days after receipt of the invoice." | translate}}</p>
      <p>{{"The content creator must ensure that they have a valid bank account." | translate}}</p>
      

      <h2>{{"Article 4: Client’s obligations and responsibilities" | translate}}</h2>
      <h3>{{"4.1 Data provided by the Client" | translate}}</h3>
      <p>{{"The Customer provides all the information and products necessary for the realization of his influence campaign when he defines the parameters of his campaign in his brief." | translate}}</p>
      <p>{{"Under no circumstances can Spotlight be held responsible for a campaign that does not meet the Customer’s expectations if the necessary elements or products provided by the Customer do not appear in the Brief or are not described in a clear and unequivocal manner." | translate}}</p>
      <p>{{"The Customer expressly authorizes Spotlight to publish the content generated by the campaigns carried out on the platform." | translate}}</p>
      <p>{{"Spotlight may refuse any campaign that does not correspond to editorial choices or if the Client has not provided all the necessary administrative, financial, and legal evidence." | translate}}</p>
      <!-- <ul>
        <li>{{"- 50% of the total VAT-inclusive amount at the campaign launch," | translate}}</li>
        <li>{{"- 50% of the total VAT-inclusive amount upon receipt of the invoice (end of the campaign)." | translate}}
        </li>
      </ul> -->
      <h3>{{"4.2 Rights and Legality" | translate}}</h3>
      <p>{{"The Client declares and warrants:" | translate}}</p>
      <p>{{"- To have the capacity and quality required to collaborate with the content creator, and that their activity is lawful in accordance with applicable laws and regulations. The Client guarantees Spotlight, as well as the content creator and/or social media publishers, against any claims in this regard." | translate}}</p>
      <p>{{"- To have and maintain all administrative and regulatory authorizations related to the manufacture of products or the production of services for sale, if applicable. The Client guarantees Spotlight, as well as the content creator and/or social media publishers, against any claims in this regard." | translate}}</p>
      <p>{{"- To hold all rights necessary for the display and exploitation of all products and/or brands that may be displayed in the course of performing the services; the Client ensures and guarantees their peaceful exploitation for the duration of these terms. The Client guarantees Spotlight, as well as the content creator and/or social media publishers, against any claims in this regard." | translate}}</p>
      <p>{{"- That the brands and/or products that may be displayed in the course of performing the services will not infringe upon the rights of third parties, especially intellectual property rights (including, among others, trademarks, domain names, and properties of websites) and will not constitute any form of infringement. The Client guarantees Spotlight, as well as the content creator and/or social media publishers, against any claims in this regard." | translate}}</p>
      <p>{{"- That the products that may be displayed in publications or posts will comply with applicable laws and regulations in public health, hygiene, safety, or any other regulated matter. The Client guarantees Spotlight against any claims from any consumer and/or third party who may have encountered any issues with a product." | translate}}</p>
      <p>{{"- To be free from any commitments that are incompatible with the perfect execution of the service obligations throughout the campaign and that it does not violate the rights of a third party." | translate}}</p>
      <p>{{"- To be in compliance with tax authorities, labor law, and social protection organizations; the Client undertakes to personally handle all declarations, as well as the settlement of charges, taxes, contributions, taxes, and sums of all kinds, and more generally, the fulfillment of any formalities incumbent upon it due to its activity and the services performed within the framework of the Client's influencer campaign. The Client guarantees Spotlight against any claims in this regard." | translate}}</p>
      
      <h3>{{"4.3 Use and Misuse" | translate}}</h3>
      <p>{{"The client is entirely responsible for the use they make of the platform, including, but not limited to, the choices of the content creator as well as their remuneration." | translate}}</p>
      
      <h3>{{"4.4 Payment and Payment Issues" | translate}}</h3>
      <p>{{"The client must ensure the availability of a valid payment method. In case of payment issues, it will have an impact on the launch or suspension of the campaign." | translate}}</p>
      
      <h3>{{"4.5 Early Termination during the Commitment Period" | translate}}</h3>
      <p>{{"In the event that the Client wishes to terminate the contract with the content creator(s) before the commitment period ends, no refund will be provided by Spotlight. However, the difference will be applied towards the Client's future campaigns." | translate}}</p>
      
      <h2>{{"Article 5: Obligations and Responsibilities of Spotlight" | translate}}</h2>
      <p>{{"- The Client expressly acknowledges that Spotlight is solely an electronic campaign management platform, particularly facilitating the connection between the Client and content creators." | translate}}</p>
      <p>{{"- Spotlight undertakes to exercise its best efforts in accordance with industry standards. The obligations of Spotlight arising from these General Terms of Sale are expressly acknowledged by the Client and the content creator as pure obligations of means." | translate}}</p>
      <p>{{"Spotlight's liability shall not be incurred in the following cases:" | translate}}</p>
      <p>{{"- Third-party intrusion into the Client's website and its various pages and contents, or the Client's site administration interface;" | translate}}</p>
      <p>{{"- Access to the Client's databases or servers hosting source files;" | translate}}</p>
      <p>{{"- Hacking of the Client's email systems by a third party;" | translate}}</p>
      <p>{{"- Computer virus attack on the Client's website." | translate}}</p>
      <p>{{"Spotlight operates properly on the latest versions of Internet Explorer, Edge, Firefox, Chrome, and Safari (versions 10 and 11 for Internet Explorer and the current version at the time of publication + the two previous versions for other browsers). However, some non-significant rendering discrepancies may exist. Spotlight cannot guarantee the compatibility of the site developed across all browsers and on all mobile devices. It is the responsibility of the Client and the content creator to implement and maintain navigation and computer systems compatible with those of Spotlight's electronic matchmaking platform." | translate}}</p>
      
      <h2>{{"Article 6: Non-Solicitation" | translate}}</h2>
      <p>{{"In order to protect the interests of Spotlight, including, but not limited to, the protection of its know-how, organization, or economic model:" | translate}}</p>
      <p>{{"The Client undertakes, unless expressly written consent is obtained from Spotlight, not to contact content creators directly or indirectly, whether through a third party, intermediary, company, or representative, to propose and carry out a similar or identical campaign to that created on the Spotlight platform without using the services of Spotlight during the entire duration of this contract and one (1) year after the contract's termination. Content creators who are contacted or approached by the Client for this purpose must immediately inform Spotlight." | translate}}</p>
      <p>{{"The Client agrees not to solicit or hire any staff members of Spotlight, including its employees responsible for the business relationship with the Client, without the prior consent of Spotlight during the entire duration of this contract and one (1) year after the contract's termination, regardless of the cause. This prohibition also applies if the contemplated recruitment follows an initial solicitation by a staff member of Spotlight." | translate}}</p>
      <p>{{"In case of non-compliance with the obligations outlined in this article, Spotlight has the right to terminate any contractual relationship with the Client, to the exclusive detriment of the latter." | translate}}</p>
      
      <h2>{{"Article 7: Commencement" | translate}}</h2>
      <p>{{"In the absence of any other indication by the Spotlight employee responsible for the business relationship with the Client, this contract comes into effect on the day the account is created by the Client and/or the Content Creator, or on the day it is created by Spotlight at the request of the Client and/or the Content Creator." | translate}}</p>
      
      <h2>{{"Article 8: Termination" | translate}}</h2>
      <p>{{"If the absence of a campaign is due to the non-validation of the campaign by Spotlight for any violation of current legislation or these General Terms of Sale, a formula described in Article 5.6 allows for the management of the early termination penalty." | translate}}</p>
      <p>{{"Termination is the responsibility of the Client, who must do so from their 'my account' space at least 72 (Seventy-Two) hours before the campaign launch date. Termination requests via email are not accepted, and no refund requests can be made on this basis." | translate}}</p>
      <p>{{"Spotlight reserves the right to terminate this contract, after written notification by email, automatically and without any indemnity, in the event of a violation of any of the clauses of the General Terms of Sale." | translate}}</p>
      <p>{{"In the event of contract termination at the initiative of the Client, the budgets already allocated to their influencer campaign on the Spotlight platform are non-refundable but can be reused in future campaigns of the Client." | translate}}</p>
      
      <h2>{{"Article 9: Assignment of Contract" | translate}}</h2>
      <p>{{"Spotlight reserves the right to assign, transfer, or convey to a third party, in any form whatsoever, the rights and obligations arising from this contract." | translate}}</p>
      
      <h2>{{"Article 10: Force Majeure" | translate}}</h2>
      <p>{{"Neither party shall be held liable to the other for non-performance or delays in the execution of an obligation under this contract that are due to the actions of the other party, following the occurrence of a force majeure event recognized by jurisprudence." | translate}}</p>
      
      <h2>{{"Article 11: Modification of General Terms of Sale" | translate}}</h2>
      <p>{{"These general terms of sale are subject to modifications." | translate}}</p>
      <p>{{"Modifications to the General Terms of Sale take effect upon their publication on the website, and the client/content creator is deemed to have acknowledged them." | translate}}</p>
      <p>{{"In the event that the client informs Spotlight of their refusal of the changes, Spotlight reserves the right to terminate this contract in accordance with the terms and conditions of Article 9 herein." | translate}}</p>
      <p>> {{"By email" | translate}}: <a href="mailto:CONTACT@SPOTLIGHTDZ.DZ">CONTACT@SPOTLIGHTDZ.DZ</a></p>

      <h2>{{"Article 12: Special Provisions" | translate}}</h2>
      <p>{{"In accordance with current regulations, Spotlight, particularly through the employee of Spotlight responsible for the business relationship with the Client, reserves the right to deviate from certain clauses of the General Terms of Sale based on negotiations conducted with the Client." | translate}}</p>
      <p>{{"Modifications or deviations from the General Terms of Sale take the form of:" | translate}} <br>
        {{"- An amendment signed by the Client (and the content creator if applicable); or" | translate}} <br>
        {{"- The establishment of Special Sales Conditions signed by the Client (and the content creator if applicable)." | translate}} <br>
      </p>
      <p>{{"To do so, the amendment or Special Sales Conditions are published along with the Brief communicated to content creators before the start of any campaign. The Client and the selected content creator must have signed the Special Sales Conditions or the amendment to ensure that these modifications are known to all parties involved." | translate}}</p>

      <h2>{{"Article 13 - Terms of Penalty Clauses" | translate}}</h2>
      <p>{{"Any violation by the Client or the content creator of any of the penalty clauses stipulated in these General Terms of Sale entails the obligation for the party proven to have committed such violation to pay the amount specified in said clause within one (1) week from the receipt of the payment request. This is without prejudice to any action for compensation for damages brought by the Party who considers itself harmed." | translate}}</p>
      <p>{{"This request is made by email with acknowledgment of receipt and specifies the violation of the penalty clause, as well as the fixed amount due and specified in these terms." | translate}}</p>

      <h2>{{"Article 14: Applicable Laws and Competent Jurisdictions" | translate}}</h2>
      <p>{{"The parties agree that the applicable law is Algerian domestic law, that disputes fall under the jurisdiction of Algerian courts, and that the courts of Algiers have exclusive competence. The language of these General Terms is French. Any translations into other languages are for informational purposes only." | translate}}</p>

    </div>
  </div>
</div>
<!-- End Terms & Conditions -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->