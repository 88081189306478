import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { CandidatesDetailsComponent } from './components/pages/candidates-details/candidates-details.component';
import { CandidatesComponent } from './components/pages/candidates/candidates.component';
import { CareerComponent } from './components/pages/career/career.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { EmployersDetailsComponent } from './components/pages/employers-details/employers-details.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { FavouriteJobsComponent } from './components/pages/favourite-jobs/favourite-jobs.component';
import { ForgetPasswordCompanyComponent } from './components/pages/forget-password-company/forget-password-company.component';
import { ForgetPasswordInfluencerComponent } from './components/pages/forget-password-influencer/forget-password-influencer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { LoginComponent } from './components/pages/login/login.component';
import { OtpEmailCompanyComponent } from './components/pages/otp-email-company/otp-email-company.component';
import { OtpEmailComponent } from './components/pages/otp-email/otp-email.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { Register2Component } from './components/pages/register2/register2.component';
import { Register3Component } from './components/pages/register3/register3.component';
import { ResetPasswordCompanyComponent } from './components/pages/reset-password-company/reset-password-company.component';
import { ResetPasswordInfluencerComponent } from './components/pages/reset-password-influencer/reset-password-influencer.component';
import { ResumeDetailsComponent } from './components/pages/resume-details/resume-details.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { AuthGuard } from './components/shared/guard/auth.guard';
import { RedirectAuthGuard } from './components/shared/guard/redirect-auth.guard';
import { Role } from './components/shared/models/role';
import { InflistComponent } from './components/pages/inflist/inflist.component';


const routes: Routes = [
    {path: '', component: HomeOneComponent},
    // {path: 'home-two', component: HomeTwoComponent},
    // {path: 'home-three', component: HomeThreeComponent},
    // {path: 'employers', component: EmployersComponent},
    // {path: 'employer-details', component: EmployersDetailsComponent},
    // {path: 'dashboard', component: DashboardComponent},
    // {path: 'single-resume', component: ResumeDetailsComponent},
    // {path: 'testimonials', component: TestimonialsComponent},
    // {path: 'pricing', component: PricingComponent},
    // {path: 'coming-soon', component: ComingSoonComponent},
    // {path: 'jobs', component: JobsComponent},
    // {path: 'favourite-jobs', component: FavouriteJobsComponent},
    // {path: 'job-details', component: JobDetailsComponent},
    // {path: 'post-a-job', component: PostAJobComponent},
    // {path: 'candidates', component: CandidatesComponent},
    // {path: 'candidate-details', component: CandidatesDetailsComponent},
    // {path: 'blog', component: BlogComponent},
    // {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'login', component: LoginComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'register-step2', component: Register2Component},
    {path: 'register-step3', component: Register3Component},
    {path: 'otp-email', component: OtpEmailComponent},
    {path: 'otp-email-company', component: OtpEmailCompanyComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'error', component: ErrorComponent},
    // {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'about', component: AboutComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'inflist', component: InflistComponent},
    { path: 'career', component: CareerComponent },
    { path: 'forgot-influencer-pass', component: ForgetPasswordInfluencerComponent},
    { path: 'reset-influencer-pass/:token', component: ResetPasswordInfluencerComponent},
    { path: 'forgot-company-pass', component: ForgetPasswordCompanyComponent},
    { path: 'reset-company-pass/:token', component: ResetPasswordCompanyComponent},
    { path : ''  , redirectTo : '', pathMatch: 'full' , canActivate : [ RedirectAuthGuard ] },
    {
        path: 'influencer',
        loadChildren: () => import('./components/pages/influencer/influencer.module').then((m) => m.InfluencerModule),
        canActivate: [AuthGuard],
        data: {
            role: [Role.Influencer]
        }
    },
    {
        path: 'company',
        loadChildren: () => import('./components/pages/company/company.module').then((m) => m.CompanyModule),
        canActivate: [AuthGuard],
        data: {
            role: [Role.Company]
        }
    },
    {
        path: 'user',
        loadChildren: () => import('./components/pages/talent/talent.module').then((m) => m.TalentModule)
    },

    {path: '**', component: ErrorComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}