<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<!-- <div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>FAQ</h2>
                    <ul>
                        <li>
                            <img src="assets/img/spotlight_icon.png" height="20px" alt="Image">
                            <a href="index.html">Home</a>
                        </li>
                        <li>
                            <span>/</span>
                        </li>
                        <li>
                            FAQ
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Page Title -->

<!-- FAQ -->
<div class="faq-area pt-100" style="background-image: url(./assets/img/FAQ/background_FAQ.png); background-size: cover; 
background-attachment: fixed;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-content">
                    <ul class="accordion">
                        <li>
                            
                            <a>{{"What is Spotlight and what does it do?"| translate}}</a>
                            <p>{{"Spotlight is an influencer marketing platform that facilitates collaborations between
                                brands and content creators. It allows brands to promote their products or services
                                through content creators on social networks."| translate}}</p>
                        </li>
                        <li>
                            <a>{{"How can I sign up on Spotlight as a brand or content creator?"| translate}}</a>
                            <p>{{"To sign up on Spotlight, visit our website and follow the registration process dedicated to brands or content creators. Fill in the required information and start exploring collaboration opportunities."| translate}}</p>
                        </li>
                        <li>
                            <a>{{"What advantages does Spotlight offer for businesses choosing to use it?"| translate}}</a>
                            <p>{{"Spotlight offers a
                                user-friendly platform, transparent collaborations, and powerful tools to maximize the impact
                                of influencer marketing campaigns. This allows businesses to promote their products
                                authentically and effectively to a targeted audience."| translate}}</p>
                        </li>
                        <li>
                            <a>{{"How does the content creator selection process work on Spotlight?"| translate}}</a>
                            <p>{{"Brands can create
                                campaigns, and then content creators can apply to participate. Brands then select the content
                                creators who best fit their needs."| translate}}</p>
                        </li>
                        <li>
                            <a>{{"What types of campaigns can I create on Spotlight as a brand?"| translate}}</a>
                            <p>{{"You can create a variety of campaigns, ranging from simple sponsored posts to live events. Spotlight offers flexibility to  meet the unique needs of each brand." | translate}}</p>
                        </li>
                        <li>
                            <a>{{"What analytical tools are available on Spotlight to track campaign performance?"| translate}}</a>
                            <p>{{"Spotlight
                                offers comprehensive analytical tools allowing brands to track the performance of their
                                campaigns in real-time, including engagement and conversion statistics."| translate}}</p>
                        </li>
                        <li>
                            <a>{{"How are collaborations managed on Spotlight to ensure transparency and effectiveness?"| translate}}</a>
                            <p>{{"Spotlight promotes transparency through detailed analytics reports, evaluations, and feedback
                                between brands and content creators, ensuring open and honest collaboration."| translate}}</p>
                        </li>
                        <li>
                            <a>{{"Are there any fees associated with using Spotlight and how are they billed?"| translate}}</a>
                            <p>{{"Spotlight
                                charges a 9% commission on transactions between brands and content creators. These fees
                                cover platform management, payment processing, and customer support."| translate}}</p>
                        </li>
                        <li>
                            <a>{{"What security measures are in place to protect user data on Spotlight?"| translate}}</a>
                            <p>{{"Security Measures for Data Protection on Spotlight in Algeria In accordance with Law 17-08 on the Protection of Personal Data in Algeria"| translate}}</p>
                        </li>
                        <li>
                            <a>{{"How can I get help or customer support if I have questions or issues with Spotlight?"| translate}}</a>
                            <p>{{"Our
                                customer support team is available to answer your questions. You can contact us via email at
                                contact@spotlightdz.dz or through our online chat service on the website."| translate}}</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-img">
                    <img src="assets/img/FAQ/faq.png" alt="FAQ">
                </div>
            </div>
        </div>

        <div class="faq-bottom">
            <h3>If you don't find your questions or need help</h3>
            <a routerLink="/contact">Contact Us</a>
        </div>
    </div>
</div>
<!-- End FAQ -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->