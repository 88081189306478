<!-- Login -->
<div class="user-form-area"  style="background-image: url('assets/img/login_bg.png'); background-size: cover; height: 100vh;">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="">
                    <img src="assets/img/registration.png" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2 translate>Reset Password</h2>
                        <!-- <p class="mb-4 text-muted tx-13 ms-0" translate>A reset link will be sent to the registered email address.</p> -->
                        <form [formGroup]="reset_pass_form" (ngSubmit)="submit()">
                            <div class="form-group">
                                <label translate>New Password
                                    <span class="text-danger">* </span>
                                </label>
                                <div class="input-group">
                                    <input [type]="isNewPasswordText ? 'text' : 'password'" name="password" formControlName="password"
                                        class="form-control" placeholder="{{'New Password' | translate}}"
                                        [ngClass]="{'has-success': reset_pass_form.get('password')?.valid}">
                                    <div class="input-group-text">
                                        <a class="link-icon" (click)="toggleNewPassword()" style="cursor: pointer; color: black;"><i class="bx"
                                                [ngClass]="!isNewPasswordText ? 'bx-show' : 'bx-hide'"></i></a>
                                    </div>
                                </div>
                                <ng-container class="invalid"
                                    *ngIf="reset_pass_form.get('password')?.hasError('required') && (reset_pass_form.get('password')?.touched || reset_pass_form.get('password')?.dirty)">
                                    <small class="text-danger" translate>Please provide new password</small>
                                </ng-container>
                                <ng-container class="invalid"
                                    *ngIf="reset_pass_form.get('password')?.hasError('pattern') && (reset_pass_form.get('password')?.touched || reset_pass_form.get('password')?.dirty)">
                                    <small class="text-danger" translate>Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character</small>
                                </ng-container>
                                <ng-container class="invalid"
                                    *ngIf="reset_pass_form.get('password')?.hasError('maxlength') && (reset_pass_form.get('password')?.touched || reset_pass_form.get('password')?.dirty)">
                                    <small class="text-danger" translate>Password is too long</small>
                                </ng-container>
                            </div>

                            <div class="form-group">
                                <label translate>Confirm Password
                                    <span class="text-danger">* </span>
                                </label>
                                <div class="input-group">
                                    <input [type]="isNewConfPasswordText ? 'text' : 'password'" name="confirm_password" formControlName="confirm_password"
                                        class="form-control" placeholder="{{'Confirm Password' | translate}}"
                                        [ngClass]="{'has-success': reset_pass_form.get('confirm_password')?.valid}">
                                    <div class="input-group-text">
                                        <a class="link-icon" (click)="toggleNewConfPassword()" style="cursor: pointer; color: black;"><i class="bx"
                                                [ngClass]="!isNewConfPasswordText ? 'bx-show' : 'bx-hide'"></i></a>
                                    </div>
                                </div>
                                <ng-container class="invalid"
                                    *ngIf="reset_pass_form.get('confirm_password')?.hasError('required') && (reset_pass_form.get('confirm_password')?.touched || reset_pass_form.get('confirm_password')?.dirty)">
                                    <small class="text-danger" translate>Please provide confirm password</small>
                                </ng-container>
                                <ng-container class="invalid"
                                    *ngIf="reset_pass_form.get('confirm_password')?.hasError('mustMatch') && (reset_pass_form.get('confirm_password')?.touched || reset_pass_form.get('confirm_password')?.dirty)">
                                    <small class="text-danger" translate>Password doesn't match</small>
                                </ng-container>
                            </div>

                            <button type="submit" class="btn" translate>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->