import { Inject, Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private translate: TranslateService,
    private router: Router,
    private auth: AuthService,
    @Inject(Injector) private readonly injector: Injector
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
    .pipe(
      // tap(data => console.log(data)),
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof HttpErrorResponse) { 
          console.error('An error occurred:', error.message);
        } else {
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error.message || error.statusText}`);
        }
        if (error.status === 401) {
           Swal.fire(
            this.translate.instant('Alert!'),
            this.translate.instant(error.error.message),
            'info'
          )
          .then(result => {
            this.auth.all_logout()
            // localStorage.clear();
            // this.router.navigate(['/auth/login']).then(() => {
            //   window.location.reload();
            // });
          })
        } else if (error.status == 0) {
          Swal.fire(
            'Connection Is Not Stable',
            'Please check your internet connection. It seems that it is not stable. <br>Detail:' + " " + error.message,
            'question'
          )
        }
        else{
          Swal.fire(
            this.translate.instant('Error!'),
            this.translate.instant(error.error.message || error.statusText),
            'error'
          )
        }
        return throwError(() => new Error(error.error.message));
      })
    );
  }
}
