<!-- Login -->
<div class="user-form-area"  style="background-image: url('assets/img/login_bg.png'); background-size: cover; height: 100vh;">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="">
                    <!-- <img src="assets/img/registartion.png" alt="User"> -->
                    <img src="assets/img/registration.png" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2 >{{"Registration" | translate}} - {{"Step" | translate}} 3</h2>
                        <form [formGroup]="register3_form" (ngSubmit)="submit()">
                            <div class="form-group">
                                <label translate>National Identity Number
                                    <span class="text-danger">* </span>
                                </label>
                                <input type="text" class="form-control" placeholder="{{'National Identity' | translate}}" formControlName="identity">
                                <ng-container class="invalid"
									*ngIf="register3_form.get('identity')?.hasError('required') && (register3_form.get('identity')?.touched || register3_form.get('identity')?.dirty)">
									<small class="text-danger" translate>Please provide National identity number</small>
								</ng-container>
                                <ng-container class="invalid"
                                    *ngIf="register3_form.get('identity')?.hasError('maxlength') && (register3_form.get('identity')?.touched || register3_form.get('identity')?.dirty)">
                                    <small class="text-danger" translate>Identity is too long</small>
                                </ng-container>
                            </div>
                            <div class="form-group">
								<label translate>National Identity Image
                                    <span class="text-danger">* </span>
                                </label>
								<input type="file" (change)="getImage($event)" name="image" formControlName="identity_image"
									class="form-control form-control-md" accept=".png, .jpg, .jpeg, .pdf">
								<ng-container class="invalid"
									*ngIf="register3_form.get('identity_image')?.hasError('required') && (register3_form.get('identity_image')?.touched || register3_form.get('identity_image')?.dirty)">
									<small class="text-danger" translate>Please provide national identity image</small>
								</ng-container>
							</div>
                            <!-- <div class="form-group">
                                <img [src]="image_result" #img (error)="img.src = 'assets/img/home-two/banner1.png'"
								style="object-fit: contain;" height="130px" width="100%">
                            </div> -->
                            <button type="submit" class="btn" translate>Submit</button>
                        </form>
                    </div>

                    <!-- <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->