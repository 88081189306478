import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  register1(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/signup/`, data)
  }

  get_otp(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/send_email_otp/`, data)
  }

  verify_otp(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/email_verify/`, data)
  }

  register2(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/questionnaire/`, data)
  }

  register3(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/submit_influencer_identity/`, data)
  }

  influencer_login(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/login/`, data)
  }

  influencer_forgot_pass_link(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/influencerSendLinkForgotPassword/`, data)
  }

  influencer_reset_password(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/influencerForgotPassword/`, data)
  }

  company_register(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}companies/signup/`, data)
  }
  
  getCompanyOtp(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}companies/send_company_email_otp/`, data)
  }

  verifyCompanyOtp(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}companies/company_email_verify/`, data)
  }

  forgotCompanyPass(data) {
    data.append('language', this.translate.currentLang);
    return this.http.post(`${environment.apiUrl}companies/companySendLinkForgotPassword/`, data)
  }

  resetCompanyPassword(data) {
    data.append('language', this.translate.currentLang);
    return this.http.post(`${environment.apiUrl}companies/companyForgotPassword/`, data)
  }

  companyLogin(data) {
    data.append('language', this.translate.currentLang);
    return this.http.post(`${environment.apiUrl}companies/company_login/`, data)
  }

  // Get talent list API
  get_talent_list(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/recently_added_influencer/`, data)
  }

  // Get talent details by ID API
  get_talent_byId(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/view_recently_influencer/`, data)
  }

  // contact api
  contact_us(data) {
    data.append('language', this.translate.currentLang)
    return this.http.post(`${environment.apiUrl}influencer/contact_us/`, data)
  }
}
