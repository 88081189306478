<app-navbar-style-three></app-navbar-style-three>
<!-- Page Title -->
<div class="page-title-area">
   <div class="d-table">
      <div class="d-table-cell">
         <div class="container">
            <div class="title-item">
               <h2 translate>Creators</h2>
               <ul>
                  <li>
                     <img src="assets/img/spotlight_icon.png" height="20px" alt="Image">
                     <a routerLink="/" translate>Home</a>
                  </li>
                  <li><span>/</span></li>
                  <li translate>Creators</li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- End Page Title -->
<!-- Campaigns --><!-- inflist.component.html -->
<!-- Campaigns -->

<div class="candidate-area pt-100 blur-list">
    <div class="container">
       <div class="row" *ngIf="people.length !== 0; else no_data">
          <ng-container *ngFor="let person of people; let i = index">
             <div class="col-lg-6 col-md-12">
                <div class="candidate-item two">
                   <div class="left">
                      <h3><a (click)="view_packages(person)" style="cursor: pointer;">{{ person.name }}</a></h3>
                      <ul class="experience">
                         <li>{{ 'Age' | translate }}: <span>{{ person.age }}</span></li>
                         <li>{{ 'Category' | translate }}:
                            <span class="text-truncate" style="max-width: 150px;" [ngbPopover]="person.categories" ngbTooltip="{{ 'Show more' }}">
                               {{ person.categories.join(', ') }}
                            </span>
                         </li>
                      </ul>
                      <h3 class="mb-0"> <a>{{ 'Social Links' | translate }} :</a></h3>
                      <ul class="experience mt-2">
                         <li class="d-flex" *ngIf="person.socialMedia.length !== 0; else no_social_links">
                            <ng-container *ngFor="let link of person.socialMedia; let i = index">
                               <a *ngIf="link.link !== ''"  target="_blank" class="ng2-smart-action social-links">
                                  <i *ngIf="link.platform === 'Twitter'" class="bx bxl-twitter"></i>
                                  <i *ngIf="link.platform === 'LinkedIn'" class="bx bxl-linkedin"></i>
                                  <!-- Add more social media icons as needed -->
                               </a>
                            </ng-container>
                         </li>
                         <ng-template #no_social_links>
                            <li>
                               <span>{{ 'No Social Links Available' | translate }}</span>
                            </li>
                         </ng-template>
                      </ul>
                   </div>
                   <div class="client">
                      <img [src]="person.image" alt="Candidate" (error)="img.src='assets/images/default-profile.jpg'" (click)="view_packages(person)" style="cursor: pointer;">
                   </div>
                </div>
             </div>
          </ng-container>
       </div>
       <ng-template #no_data>
          <p class="fw-bold text-center">{{ 'No People Found' | translate }}</p>
       </ng-template>
    </div>
 </div>
 
 <div class="view-btn-container">
   <div class="view-btn">
      <svg width="150" height="50" xmlns="http://www.w3.org/2000/svg" class="svg-container">
         <rect x="0" y="0" width="150" height="50" rx="25" ry="25" fill="#db5e70" />
         <text x="50%" y="50%" text-anchor="middle" dy=".3em" fill="#fff" font-size="16"
            font-family="Arial, sans-serif">
            <a style="cursor: pointer;" class="fw-bold" (click)="registration()">{{'View More' |translate}}</a>
         </text>
      </svg>
   </div>
</div>
