import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { AuthApiService } from '../../services/auth-api.service';

@Component({
  selector: 'app-otp-email',
  templateUrl: './otp-email.component.html',
  styleUrls: ['./otp-email.component.scss']
})
export class OtpEmailComponent implements OnInit {

  otp_form: FormGroup
  influencer_id;
  influencer_custom_id;
  influencer_email;

  time: number = 0;
  display;
  interval;
  isDisabled = false
  duration = 60
  timeLeft: number;
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    this.verify_otp();
  }
  // @Input() isDisabled! : false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api: AuthApiService,
    private translate: TranslateService
  ) {
    this.route.queryParams.subscribe((param) => {
      if (param && param.data) {
        let data = JSON.parse(decodeURIComponent(atob(param.data)))
        // this.influencer_id = data.id
        this.influencer_custom_id = data.custom_id
        this.influencer_email = data.email
        // console.log(this.influencer_email)
        // console.log(this.influencer_custom_id)
      } else {
        this.router.navigate(['/register'])
      }
    })
  }

  ngOnInit() {
    this.otp_form = this.fb.group({
      otp: ['', [Validators.required]]
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.otp_form.controls;
  }

  verify_otp() {
    this.markFormGroupTouched(this.otp_form)
    if (this.otp_form.invalid) {
      return;
    }
    // let user_data = JSON.parse(localStorage.getItem('influencer_data'))
    let form_data = new FormData()
    // form_data.append('influencer_id', user_data.id)
    // form_data.append('influencer_custom_id', user_data.custom_id)
    form_data.append('influencer_id', this.influencer_id)
    form_data.append('influencer_custom_id', this.influencer_custom_id)
    form_data.append('influencer_email_otp', this.otp_form.get('otp').value)
    this.api.verify_otp(form_data).subscribe((res: any) => {
      if (res.status === 'success') {
        let param = btoa(encodeURIComponent(JSON.stringify({ 'custom_id': this.influencer_custom_id })))
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        })
        this.router.navigate(['/register-step2'], { queryParams: { data: param } })
        // .then(() => {
        //   this.router.navigate(['/register-step2'], {queryParams: {data:param}})
        // })
      }
    })
  }

  resend_otp() {
    let form_data = new FormData()
    form_data.append('email', this.influencer_email)
    this.api.get_otp(form_data).subscribe((res: any) => {
      if (res.status == 'success') {
        this.isDisabled = true;
        // setTimeout(() => {
        //   this.isDisabled = false;
        // }, 60000)
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        })
      }
    })
  }

  startTimer() {
    console.log("=====>");
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
      this.display = this.transform(this.time)
    }, 10000);
  }
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ':' + (value - minutes * 60);
  }
  pauseTimer() {
    clearInterval(this.interval);
  }

  keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
     * Marks all controls in a form group as touched
     * @param formGroup The form group to touch
     */
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  handleEvent(left) {
		setTimeout(() => {
			console.log(this.timeLeft)
			this.timeLeft = left
			if (this.timeLeft <= 0) {
				this.isDisabled = false
			}
		}, 1000)
    // this.isDisabled = false
		console.log(this.timeLeft)
	}

}
