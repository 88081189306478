import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private translateService: TranslateService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(this.auth.currentUserValue) {
      const token = this.auth.currentUserValue.token;
      if(token) {
        request = request.clone({
          setHeaders: {
            'Authorization' : `Bearer ${token}`,
            // 'Accept-Language': localStorage.getItem('lang')
          }
        })
      }
    }
    return next.handle(request);
  }
}
