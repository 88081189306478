import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {  ViewChild, ElementRef } from '@angular/core';

interface Person {
  name: string;
  image: string;
  age: number;
  categories: string[];
  socialMedia: { platform: string; link: string }[];
}
@Component({
  selector: 'app-inflist',
  templateUrl: './inflist.component.html',
  styleUrls: ['./inflist.component.scss']
})


export class InflistComponent  implements OnInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  constructor(
    private router: Router,
   
  ) { }

  ngOnInit(): void {
  }

  people: Person[] = [
    {
      name: 'Aness BHD',
      image: 'assets/img/aness.jpg',
      age: 30,
      categories: ['Category1', 'Category2'],
      socialMedia: [
        { platform: 'Twitter', link: 'https://twitter.com/johndoe' },
        { platform: 'LinkedIn', link: 'https://linkedin.com/in/johndoe' }
      ]
    },
    {
      name: 'Iness Abdeli',
      image: 'assets/img/ines.jpg',
      age: 25,
      categories: ['Category2', 'Category3'],
      socialMedia: [
        { platform: 'Twitter', link: 'https://twitter.com/janesmith' },
        { platform: 'Instagram', link: 'https://instagram.com/janesmith' }
      ]
    },
    {
      name: 'Anis Hamidi',
      image: 'assets/img/anis.jpg',
      age: 25,
      categories: ['Category2', 'Category3'],
      socialMedia: [
        { platform: 'Twitter', link: 'https://twitter.com/janesmith' },
        { platform: 'Instagram', link: 'https://instagram.com/janesmith' }
      ]
    },
    {
      name: 'Mounia Benfeghoul',
      image: 'assets/img/mounia.jpg',
      age: 25,
      categories: ['Category2', 'Category3'],
      socialMedia: [
        { platform: 'Twitter', link: 'https://twitter.com/janesmith' },
        { platform: 'Instagram', link: 'https://instagram.com/janesmith' }
      ]
    },
    {
      name: 'Zaki Lrc',
      image: 'assets/img/zaki.jpg',
      age: 25,
      categories: ['Category2', 'Category3'],
      socialMedia: [
        { platform: 'Twitter', link: 'https://twitter.com/janesmith' },
        { platform: 'Instagram', link: 'https://instagram.com/janesmith' }
      ]
    },
    {
      name: 'Rachidou',
      image: 'assets/img/rachidou.jpg',
      age: 25,
      categories: ['Category2', 'Category3'],
      socialMedia: [
        { platform: 'Twitter', link: 'https://twitter.com/janesmith' },
        { platform: 'Instagram', link: 'https://instagram.com/janesmith' }
      ]
    }
    // Add more people as needed
  ];
  
  registration() {
    this.router.navigate(['/register'])
  }



  togglePlayPause(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;

    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

}
