<!-- Login -->
<div class="user-form-area"  style="background-image: url('assets/img/login_bg.png'); background-size: cover; height: 100vh;">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="">
                    <img src="assets/img/registration.png" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2 translate>OTP Verification</h2>
                        <p class="mb-4 text-muted tx-13 ms-0" translate>Get the verification code
                            from your email</p>
                        <form [formGroup]="otp_form" (ngSubmit)="verify_otp()">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="OTP" formControlName="otp"
                                (keypress)="keyPress($event)" maxlength="6">
                                <ng-container class="invalid"
                                *ngIf="otp_form.get('otp')?.hasError('required') && (otp_form.get('otp')?.touched || otp_form.get('otp')?.dirty)">
                                    <small class="text-danger" translate>OTP is required</small>
                                </ng-container>
                            </div>

                            <button type="submit" class="btn" translate>Verify</button>
                        </form>
                    </div>

                    <!-- <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->