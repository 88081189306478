<!-- Login -->
<div class="user-form-area"  style="background-image: url('assets/img/login_bg.png'); background-size: cover; height: 100vh;">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="">
                    <img src="assets/img/registration.png" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2 translate>Forgot Password</h2>
                        <p class="mb-4 text-muted tx-13 ms-0" translate>A reset link will be sent to the registered email address.</p>
                        <form [formGroup]="forgot_pass_form" (ngSubmit)="submit()">
                            <div class="form-group">
                                <label for="email" translate>Email Address
                                    <span class="text-danger">* </span>
                                </label>
                                <input type="email" class="form-control" placeholder="Email" formControlName="email">
                                <ng-container class="invalid"
                                    *ngIf="forgot_pass_form.get('email')?.hasError('required') && (forgot_pass_form.get('email')?.touched || forgot_pass_form.get('email')?.dirty)">
                                    <small class="text-danger" translate>Please provide email</small>
                                </ng-container>
                                <ng-container class="invalid"
                                    *ngIf="forgot_pass_form.get('email')?.hasError('email') && (forgot_pass_form.get('email')?.touched || forgot_pass_form.get('email')?.dirty)">
                                    <small class="text-danger" translate>Please provide valid email</small>
                                </ng-container>
                            </div>

                            <button type="submit" class="btn" translate>Submit</button>
                            <a class="forget-button float-start mt-2" routerLink="/login"><i class="flaticon-left-arrow one"></i>&nbsp;{{"Back to login" | translate}}</a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->