import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from '../../shared/validations/passwordValidator';
import Swal from 'sweetalert2';
import { AuthApiService } from '../../services/auth-api.service';

@Component({
  selector: 'app-reset-password-influencer',
  templateUrl: './reset-password-influencer.component.html',
  styleUrls: ['./reset-password-influencer.component.scss']
})
export class ResetPasswordInfluencerComponent implements OnInit {

  token
  reset_pass_form: FormGroup;
  isNewPasswordText = false
  isNewConfPasswordText = false
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api: AuthApiService
  ) {
    this.token = this.route.snapshot.params['token']
    console.log('token :'+this.token)
  }

  ngOnInit(): void {
    this.reset_pass_form = this.fb.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'), Validators.maxLength(20)]],
      confirm_password: ['', [Validators.required]]
    },
    {
      validator: MustMatch('password', 'confirm_password')
    } as AbstractControlOptions)
  }

  toggleNewPassword(){
    this.isNewPasswordText = ! this.isNewPasswordText
  }
  toggleNewConfPassword(){
    this.isNewConfPasswordText = ! this.isNewConfPasswordText
  }

  submit() {
    this.markFormGroupTouched(this.reset_pass_form)
    if(this.reset_pass_form.invalid) {
      return;
    }
    let form_data = new FormData()
    form_data.append('new_password', this.reset_pass_form.get('password').value)
    form_data.append('token', this.token)
    this.api.influencer_reset_password(form_data).subscribe((res: any) => {
      if(res.status == 'success') {
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          text: res.message
        })
        this.router.navigate(['/login'])
        // .then(() => {
        //   this.router.navigate(['/login'])
        // })
      }
    })
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
