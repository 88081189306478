import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloaderComponent } from '../common/preloader/preloader.component';
import { NavbarStyleOneComponent } from '../common/navbar-style-one/navbar-style-one.component';
import { FooterStyleOneComponent } from '../common/footer-style-one/footer-style-one.component';
import { NavbarStyleTwoComponent } from '../common/navbar-style-two/navbar-style-two.component';
import { NavbarStyleThreeComponent } from '../common/navbar-style-three/navbar-style-three.component';
import { FooterStyleTwoComponent } from '../common/footer-style-two/footer-style-two.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    PreloaderComponent,
    NavbarStyleOneComponent,
    FooterStyleOneComponent,
    NavbarStyleTwoComponent,
    NavbarStyleThreeComponent,
    FooterStyleTwoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [
    PreloaderComponent,
    NavbarStyleOneComponent,
    FooterStyleOneComponent,
    NavbarStyleTwoComponent,
    NavbarStyleThreeComponent,
    FooterStyleTwoComponent
  ]
})
export class SharedModule { }
